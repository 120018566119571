import React, { useEffect, useState, useMemo } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../../assets/icons/people.svg';
import { CancelButton, SubmitButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn, FlexRowEnd } from '../../../../containers/ScreenContainers';
import { STAFF_TYPES } from '../../../../data/constants';
import useGetFirstDegrees from '../../../../hooks/useGetFirstDegrees';
import useGetSecondDegrees from '../../../../hooks/useGetSecondDegrees';
import { getSavedAcademicStructure } from '../../../../redux/academic-structure/actions';
import { getDesignationsInUnit } from '../../../../redux/designations/actions';
import { getActiveOrganisationUnits } from '../../../../redux/organisation-unit/actions';
import { createStaff, getPersonnelStaff } from '../../../../redux/staff/actions';
import { capitalizeEachWord, capitalizeFirstLetter } from '../../../../utils';

import Preview from './Preview';
import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
    overflow-y-auto
    mt-[3.2rem]
`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    // grid-rows-4
    gap-x-6
    gap-y-5
`;

const FACULTY_UNIT_NAME = 'academics';

const staffTypeOptions = [STAFF_TYPES.Teaching, STAFF_TYPES.NonTeaching];

const developmentFlag = process.env.REACT_APP_ENV;

const CreateStaff = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.staff);
    const { isLoading: facLoading } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);
    const { activeOrgUnits: organisationUnits } = useSelector((state) => state.organisationUnits);
    const { isLoading: designationLoading, unitDesignations } = useSelector((state) => state.designations);
    const { /*isLoading: acadLoading,*/ savedAcademicStructure } = useSelector((state) => state.academicStructure);

    const academicUnits = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));

    const [step, setStep] = useState(1);
    const [staffList, setStaffList] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedOrgUnit, setSelectedOrgUnit] = useState(null);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedAcadUnit, setSelectedAcadUnit] = useState(null);
    const [selectedSecondarySecondDegree, setSelectedSecondarySecondDegree] = useState([]);
    const { academicStructure, academicStructureId } = selectedFaculty || {};
    const splitStruct = academicStructure?.split('-');

    const { firstDegrees } = useGetFirstDegrees(selectedAcadUnit?.name);
    const { secondDegrees } = useGetSecondDegrees(selectedAcadUnit?.name, selectedFaculty);

    const unitsToUse = useMemo(
        () => organisationUnits?.filter((item) => item.to)?.sort((a, b) => a?.unitName?.localeCompare(b?.unitName)),
        [organisationUnits],
    );

    const filteredStaff = useMemo(
        () =>
            staffList
                // ?.filter((item) => !item.appliedInsidePersonnel)
                ?.sort((a, b) => a?.fullName?.localeCompare(b?.fullName)),
        [staffList],
    );

    const handleSubmit = async (formValues) => {
        const {
            firstDegree,
            secondDegree,
            organizationUnit,
            staffType,
            staffName,
            isAddToSecondDegree, //not needed on payload
            academicUnit, //not needed on payload
            ...otherFormValues
        } = formValues;

        const unit = organizationUnit?.toLowerCase();
        const firstDegreeField = splitStruct?.[0];
        const secondDegreeField = splitStruct?.[1] === 'program' ? 'subProgram' : splitStruct?.[1];
        const secondarySecondDegreeField =
            splitStruct?.[1] === 'program'
                ? 'secondarySubProgram'
                : 'secondary' + capitalizeFirstLetter(secondDegreeField);
        const allowSecondDegres =
            unit === FACULTY_UNIT_NAME && (isAddToSecondDegree || staffType === STAFF_TYPES.Teaching);

        const secondarySecondDegreeIds = selectedSecondarySecondDegree
            .filter((item) => item.departmentName !== secondDegree)
            .map((item) => item._id);

        const payload = {
            ...otherFormValues,
            organizationUnit,
            staffId: selectedStaff._id,
            ...(unit === FACULTY_UNIT_NAME ? { [firstDegreeField]: firstDegree } : {}),
            ...(allowSecondDegres ? { [secondDegreeField]: secondDegree } : {}),
            ...(allowSecondDegres && secondarySecondDegreeIds?.length > 0
                ? { [secondarySecondDegreeField]: secondarySecondDegreeIds }
                : {}),
            ...(unit !== FACULTY_UNIT_NAME ? { staffType: STAFF_TYPES.NonTeaching } : { staffType }),
        };

        const res = await dispatch(createStaff(academicStructureId, payload));

        if (res) {
            toast.success('Staff added successfully!');
            navigate(-1);
        }
    };

    useEffect(() => {
        const getStaff = async () => {
            const res = await dispatch(getPersonnelStaff());
            if (res) {
                setStaffList(res);
            } else {
                setStaffList([]);
            }
        };

        getStaff();
    }, [dispatch]);

    useEffect(() => {
        if (selectedOrgUnit) {
            dispatch(getDesignationsInUnit(selectedOrgUnit?._id));
        }
    }, [dispatch, selectedOrgUnit]);

    // useEffect(() => {
    //     dispatch(getFacultyThirdLevel());
    // }, [dispatch]);

    useEffect(() => {
        dispatch(getActiveOrganisationUnits());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    return (
        <>
            <GoBack title={`Staff Management`} subTitle="Add New Staff" />

            <Container>
                <Formik
                    initialValues={{
                        organizationUnit: '',
                        staffType: '',
                        designation: '',
                        staffName: '',
                        firstDegree: '',
                        secondDegree: '',
                        isAddToSecondDegree: false,
                        academicUnit: '',
                        // hasThirdDegree,
                    }}
                    enableReinitialize
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        setStep(2);
                    }}
                >
                    {({ errors, handleChange, values, touched, setFieldValue }) => (
                        <Form>
                            {step === 1 && (
                                <>
                                    <FlexCentredRow className="gap-2 mb-8">
                                        <PaperIcon />
                                        <Text weight="600" size="1.6rem">
                                            Add Staff
                                        </Text>
                                    </FlexCentredRow>
                                    <GridRows>
                                        <Select
                                            name="staffName"
                                            searchable
                                            objProp="fullName"
                                            label="Staff"
                                            data={filteredStaff}
                                            placeholder="Select Staff"
                                            withSubLabel
                                            subLabelObjProp="staffEmail"
                                            useComponentState
                                            passedSelectedItems={
                                                values.staffName ? { staffName: values.staffName } : null
                                            }
                                            onChange={(selected) => {
                                                setFieldValue('staffName', selected[0].fullName);
                                                setSelectedStaff(selected[0]);
                                            }}
                                            error={errors.staffName}
                                        />

                                        <Select
                                            name="organizationUnit"
                                            objProp="unitName"
                                            label="Organization Unit"
                                            data={unitsToUse}
                                            placeholder="Select Unit"
                                            passedSelectedItems={
                                                values.organizationUnit
                                                    ? { unitName: `${values.organizationUnit}` }
                                                    : null
                                            }
                                            onChange={(selected) => {
                                                setFieldValue('organizationUnit', selected[0].unitName);
                                                setSelectedOrgUnit(selected[0]);
                                                if (
                                                    //Only academics unit staff can be Teaching staff
                                                    values.staffType === STAFF_TYPES.Teaching &&
                                                    selected[0].unitName.toLowerCase() !== FACULTY_UNIT_NAME
                                                ) {
                                                    setFieldValue('staffType', '');
                                                }
                                            }}
                                            error={errors.organizationUnit}
                                        />

                                        {values.organizationUnit?.toLowerCase() === FACULTY_UNIT_NAME && (
                                            <Select
                                                name="academicUnit"
                                                objProp="name"
                                                label="Academic Unit"
                                                data={academicUnits}
                                                placeholder="Select Academic Unit"
                                                passedSelectedItems={
                                                    values.academicUnit ? [{ name: `${values.academicUnit}` }] : null
                                                }
                                                onChange={(selected) => {
                                                    setSelectedFaculty(null);
                                                    setFieldValue('firstDegree', '');
                                                    setSelectedAcadUnit(selected[0]);
                                                    setFieldValue('academicUnit', selected[0].name);
                                                }}
                                                error={errors.academicUnit}
                                            />
                                        )}
                                        {values.organizationUnit?.toLowerCase() === FACULTY_UNIT_NAME &&
                                            selectedAcadUnit && (
                                                <FlexColumn>
                                                    <Select
                                                        name="firstDegree"
                                                        objProp="facultyName"
                                                        isLoading={facLoading || collegeLoading}
                                                        label={`${selectedAcadUnit?.name} ${
                                                            developmentFlag === 'development'
                                                                ? selectedFaculty?.academicStructure || ''
                                                                : ''
                                                        }`}
                                                        placeholder={`Select ${selectedAcadUnit?.name}`}
                                                        data={firstDegrees}
                                                        useComponentState={false}
                                                        passedSelectedItems={
                                                            values.firstDegree
                                                                ? [{ facultyName: values.firstDegree }]
                                                                : null
                                                        }
                                                        onChange={(selected) => {
                                                            setFieldValue('secondDegree', '');
                                                            setSelectedFaculty(selected[0]);
                                                            setFieldValue('firstDegree', selected[0].facultyName);
                                                        }}
                                                        error={errors.firstDegree}
                                                    />

                                                    {values.firstDegree &&
                                                        values.staffType === STAFF_TYPES.NonTeaching && (
                                                            <FlexCentredRow className="mt-2 gap-4">
                                                                <input
                                                                    type="checkbox"
                                                                    id={'secondDegree'}
                                                                    className="h-7 w-7 cursor-pointer"
                                                                    onChange={() => {
                                                                        setFieldValue(
                                                                            'isAddToSecondDegree',
                                                                            !values.isAddToSecondDegree,
                                                                        );
                                                                    }}
                                                                    checked={values.isAddToSecondDegree}
                                                                />
                                                                <label
                                                                    htmlFor="secondDegree"
                                                                    className="text-[#9CA3AF] font-bold text-[1.2rem] mb-0"
                                                                >
                                                                    Do you want to add staff to {splitStruct?.[1]}?
                                                                </label>
                                                            </FlexCentredRow>
                                                        )}
                                                </FlexColumn>
                                            )}
                                        <Select
                                            name="staffType"
                                            objProp="name"
                                            label="Employment Type"
                                            placeholder="Employment Type"
                                            data={(values.organizationUnit.toLowerCase() === FACULTY_UNIT_NAME
                                                ? staffTypeOptions
                                                : [STAFF_TYPES.NonTeaching]
                                            ).map((item) => ({ name: item }))}
                                            passedSelectedItems={
                                                values.staffType ? [{ name: `${values.staffType}` }] : null
                                            }
                                            useComponentState={false}
                                            onChange={(selected) => {
                                                setFieldValue('staffType', selected[0].name);
                                            }}
                                            error={errors.staffType}
                                        />

                                        {values.organizationUnit.toLowerCase() === FACULTY_UNIT_NAME &&
                                            (values.isAddToSecondDegree || values.staffType === STAFF_TYPES.Teaching) &&
                                            selectedFaculty && (
                                                <>
                                                    <Select
                                                        name="secondDegree"
                                                        objProp="departmentName"
                                                        placeholder={capitalizeFirstLetter(splitStruct?.[1])}
                                                        label={`Primary ${capitalizeFirstLetter(splitStruct?.[1])}`}
                                                        isLoading={facLoading || collegeLoading}
                                                        data={secondDegrees}
                                                        passedSelectedItems={
                                                            values.secondDegree
                                                                ? [{ departmentName: `${values.secondDegree}` }]
                                                                : null
                                                        }
                                                        useComponentState={false}
                                                        onChange={(selected) => {
                                                            setFieldValue('secondDegree', selected[0].departmentName);
                                                        }}
                                                        error={errors.secondDegree}
                                                    />
                                                    {values.secondDegree && (
                                                        <Select
                                                            name="secondarySecondDegree"
                                                            objProp="departmentName"
                                                            multiSelect
                                                            useComponentState={false}
                                                            placeholder={capitalizeFirstLetter(splitStruct?.[1])}
                                                            label={`Secondary ${capitalizeFirstLetter(
                                                                splitStruct?.[1],
                                                            )} (Optional)`}
                                                            isLoading={facLoading || collegeLoading}
                                                            data={secondDegrees.filter(
                                                                (item) => item.departmentName !== values.secondDegree,
                                                            )}
                                                            passedSelectedItems={selectedSecondarySecondDegree}
                                                            onChange={(selected) => {
                                                                setSelectedSecondarySecondDegree(selected);
                                                            }}
                                                        />
                                                    )}
                                                    {/* {hasThirdDegree && (
                                                    <Select
                                                        name="thirdDegree"
                                                        objProp="departmentName"
                                                        placeholder={capitalizeFirstLetter(splitStruct?.[2])}
                                                        label={capitalizeFirstLetter(splitStruct?.[2])}
                                                        isLoading={facLoading || collegeLoading}
                                                        data={departments}
                                                        passedSelectedItems={
                                                            values.thirdDegree
                                                                ? [{ departmentName: `${values.thirdDegree}` }]
                                                                : null
                                                        }
                                                        useComponentState={false}
                                                        onChange={(selected) => {
                                                            setFieldValue('thirdDegree', selected[0].departmentName);
                                                        }}
                                                        error={errors.thirdDegree}
                                                    />
                                                )} */}
                                                </>
                                            )}

                                        <Select
                                            name="designation"
                                            objProp="designation"
                                            label="Designation"
                                            placeholder="Designation"
                                            data={unitDesignations}
                                            isLoading={designationLoading}
                                            passedSelectedItems={
                                                values.designation ? { designation: `${values.designation}` } : null
                                            }
                                            onChange={(selected) => {
                                                setFieldValue('designation', selected[0].designation);
                                            }}
                                            error={errors.designation}
                                        />
                                    </GridRows>
                                    <FlexRowEnd className="gap-3 mt-12">
                                        <CancelButton type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </CancelButton>
                                        <SubmitButton type="submit">Next</SubmitButton>
                                    </FlexRowEnd>
                                </>
                            )}
                            {step === 2 && (
                                <Preview
                                    isLoading={isLoading}
                                    setStep={setStep}
                                    values={values}
                                    onSubmit={() => handleSubmit(values)}
                                    splitStruct={splitStruct}
                                    secondarySecondDegree={selectedSecondarySecondDegree}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default CreateStaff;

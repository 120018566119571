import tw, { styled } from 'twin.macro';

// import './btn.css';

const TwButton = tw.button`
    flex
    items-center
    justify-center
    flex-row
    font-medium
    // px-[1em]
    py-[.4em]
`;

const LoaderDiv = styled.div`
    border: 4px solid #e5e7eb;
    border-top: 4px solid #6366f1;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
    margin-left: 0.5rem;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const Button = styled(TwButton)`
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    color: ${({ color }) => color || '#1F2937'};
    border-radius: ${({ radius }) => radius || '4px'};
    border: ${({ border }) => border || 'none'};
    height: ${({ height }) => height || '4rem'};
    outline: none !important;
    font-size: ${({ size }) => size || '1.4rem'};
    padding-left: ${({ left }) => left || '1em'};
    padding-right: ${({ right }) => right || '1em'};
    margin-top: ${({ top }) => top || null};
    margin-bottom: ${({ bottom }) => bottom || 0};
    font-weight: ${({ weight }) => weight || 500};
    line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
    box-shadow: ${({ shadow }) => shadow || 'none'}
    display: flex;
    align-items: center;
    gap: 1.315rem;

    &:disabled {
        opacity: 0.5;
    }
    /* &:hover {
        background-color: #4f46e5;
    }
    &:focus {
        border: 2px solid #a5b4fc !important;
    }
    &:active {
        background-color: #4338ca;
    } */
`;

export const SecondaryButton = styled(Button)`
    &:hover {
        /* background-color: #f9fafb; */
        background-color: darken;
    }
    &:focus {
        border: 2px solid #c7d2fe !important;
    }
    &:active {
        background-color: #e5e7eb;
    }
`;

export const SuccessButton = styled(Button)`
    &:hover {
        background-color: #059669;
    }
    &:focus {
        border: 2px solid #c7d2fe !important;
    }
    &:active {
        background-color: #047857;
    }
`;

export const LoadingButton = ({ loading, isLoading, children, bgColor = '#6366F1', color = '#ffffff', ...props }) => (
    <Button bgColor={bgColor} color={color} {...props}>
        {children}
        {(loading || isLoading) && <LoaderDiv />}
    </Button>
);

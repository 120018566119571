import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChangeIcon } from '../../../../assets/icons/change-status.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
// import Remita from '../../../../assets/icons/Remita.svg';
import { ConfirmActionDialogue } from '../../../../components';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    deletePaymentGatewayById,
    getAllPaymentGateways,
    toggleGatewayStatus,
} from '../../../../redux/bursary/actions';

import ChangeGatewayStatus from './change-gateway-status';

const ReceivingAccounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.bursary);

    const [activePayments, setActivePayments] = useState([]);
    const [refetchGateways, setRefetchGateways] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllPaymentGateways());
            setActivePayments(data);
        };

        getActiveGateways();
    }, [dispatch, refetchGateways]);

    const fields = [
        {
            header: 'Business Name',
            accessorKey: 'businessName',
            cell: (props) => (
                <p
                    style={{ color: '#2563EB' }}
                    // onClick={() => navigate(`${props?.row?.original?._id}`)}
                    className="cursor-pointer"
                >
                    {props.getValue()}
                </p>
            ),
        },
        {
            header: 'Provider',
            accessorKey: 'provider',
            cell: ({ row }) => (
                <p style={{ color: '#6B7280', textTransform: 'capitalize', fontSize: '14px', fontWeight: 400 }}>
                    {row.original.provider}
                </p>
            ),
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: ({ row }) => (
                <p style={{ color: row.original.active ? '#059669' : '#EF4444', fontSize: '12px', fontWeight: 500 }}>
                    {row.original.active ? 'Enabled' : 'Disabled'}
                </p>
            ),
        },
        {
            header: 'Webhook',
            accessorKey: 'webhookUrl',
            cell: ({ row }) => (
                <div className="flex items-center gap-4">
                    <p style={{ color: '#2563EB' }}>{row?.original?.webhookUrl?.slice(0, 25)}</p>
                    {row?.original?.webhookUrl && (
                        <CopyIcon
                            onClick={() => {
                                navigator.clipboard.writeText(row?.original?.webhookUrl);
                                toast.success('Webhook copied');
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                </div>
            ),
        },
        {
            header: 'Created At',
            accessorKey: 'dateCreated',
            cell: (props) => (
                <p style={{ color: '#6B7280' }}>
                    {props.getValue()
                        ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                              props.getValue(),
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
        {
            header: 'Last Updated',
            accessorKey: 'dateUpdated',
            cell: (props) => (
                <p style={{ color: '#6B7280' }}>
                    {props.getValue()
                        ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                              props.getValue(),
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
    ];

    const afterActionSideEffect = () => {
        setSelectedItem(null);
        setOpenDropdown(null);
        setInputValue('');
        setRefetchGateways(!refetchGateways);
    };

    const handleToggleStatus = async () => {
        const res = await dispatch(toggleGatewayStatus(selectedItem?._id));
        if (res) {
            afterActionSideEffect();
        }
    };

    const handleDeleteGateway = async () => {
        const res = await dispatch(deletePaymentGatewayById(selectedItem?._id));
        if (res) {
            afterActionSideEffect();
        }
    };

    return (
        <div className="">
            <PageLayout
                pageTitle="Payment Gateway"
                secondaryButtonText="Add Gateway"
                onSecondaryButtonClick={() => navigate('add-gateway')}
                onActionBtnClick={() => {
                    setOpenDropdown(!openDropdown);
                }}
                actionOpen={openDropdown}
                actionClose={() => setOpenDropdown(false)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Gateway',
                        click: () => {
                            navigate('edit-gateway', { state: selectedItem });
                        },
                        disabled: !selectedItem,
                    },
                    {
                        icon: <ChangeIcon />,
                        name: 'Change Status',
                        click: () => {
                            setOpenDropdown('change-status');
                        },
                        disabled: !selectedItem,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Gateway',
                        click: () => {
                            setOpenDropdown('delete');
                        },
                        disabled: !selectedItem,
                    },
                ]}
                fields={fields}
                data={activePayments || []}
                onRowClick={(item) => {
                    setSelectedItem(item);
                }}
                isActionable
                actionType="radio"
                loading={isLoading}
            />
            <ConfirmActionDialogue
                show={openDropdown === 'delete'}
                close={() => setOpenDropdown(null)}
                title="Delete this Gateway?"
                subtitle="This gateway will be deleted totally."
                borderLine
                bodyText="To confirm deletion, enter the 'delete' in the text field below."
                input
                noStyle
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                btn2Text="Yes, Delete"
                bgColor={'#EF4444'}
                confirmAction={handleDeleteGateway}
                isLoading={isLoading}
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
            />
            <ChangeGatewayStatus
                show={openDropdown === 'change-status'}
                close={() => setOpenDropdown(null)}
                selectedGateway={selectedItem}
                isLoading={isLoading}
                confirmAction={() => handleToggleStatus()}
            />
        </div>
    );
};

export default ReceivingAccounts;

import React, { useEffect, useRef, useState } from "react";

import { Formik, Form, Field } from "formik";
import ReactQuill from "react-quill"; 
import { useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css"; 
import tw from "twin.macro";
import * as Yup from "yup";

import { Select } from "../../../../components";
import { Button } from "../../../../components/buttons";
import ToggleSwitch from "../../../../components/inputs/switch";
import { Text } from "../../../../containers/MesssageContainers";
import {
  FlexCentredRow,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from "../../../../containers/ScreenContainers";
import { updatePayrollNotification } from "../../../../redux/bursary/actions";

const Container = tw.div`
  bg-white
  p-[3.5rem]
  rounded-2xl
  max-w-[1000px]
`;

const PayrollNotification = ({ payrollNotifications }) => {
  const dispatch = useDispatch();
  const quillRef = useRef(null);

  
  const [initialValues, setInitialValues] = useState({
    enabled: false,
    customMessage: {
      content: "",
      plainText: "",
      variables: [],
    },
  });
  
  useEffect(() => {
    if (payrollNotifications) {
      setInitialValues({
        enabled: payrollNotifications?.enabled || false,
        customMessage: {
          content: 
           payrollNotifications?.customMessage?.content ||
          `<div class="text-gray-800 bg-white p-4 rounded-md">
          <p class="text-lg font-semibold">Dear 
            <span class="variable bg-gray-200 px-2 py-1 rounded-md text-blue-600 cursor-default" contenteditable="false" data-var="[EMPLOYEE_NAME]">[EMPLOYEE_NAME]</span>,
          </p>
          <p class="mt-2 text-gray-700">
            We are pleased to inform you that a new payroll has been created for 
            <span class="variable bg-gray-200 px-2 py-1 rounded-md text-blue-600 cursor-default" contenteditable="false" data-var="[PAY_PERIOD]">[PAY_PERIOD]</span>. 
            Your salary and applicable allowances will be processed shortly.
          </p>
          <p class="mt-2 text-gray-700">
            You can view the details of this payroll, including breakdowns and payment status, in your employee portal. 
            If you have any questions or concerns, please reach out to 
            <span class="variable bg-gray-200 px-2 py-1 rounded-md text-blue-600 cursor-default" contenteditable="false" data-var="[HR_CONTACT]">[HR_CONTACT]</span>.
          </p>
          <p class="mt-2 text-gray-700">
            Thank you for your dedication and contributions to 
            <span class="variable bg-gray-200 px-2 py-1 rounded-md text-blue-600 cursor-default" contenteditable="false" data-var="[COMPANY_NAME]">[COMPANY_NAME]</span>.
          </p>
          <p class="mt-4 text-gray-900 font-semibold">Best regards,</p>
          <p class="text-gray-900 font-semibold">
            <span class="variable bg-gray-200 px-2 py-1 rounded-md text-blue-600 cursor-default" contenteditable="false" data-var="[UNIVERSITY_NAME]">[UNIVERSITY_NAME]</span>
          </p>
          <p class="text-gray-700">Bursar</p>
        </div>`,
          plainText: 
           payrollNotifications?.customMessage?.plainText || 
            `Dear [EMPLOYEE_NAME], 
  
            We are pleased to inform you that a new payroll has been created for [PAY_PERIOD]. 
            Your salary and applicable allowances will be processed shortly. 
  
            You can view the details of this payroll, including breakdowns and payment status, in your employee portal. 
            If you have any questions or concerns, please reach out to [HR_CONTACT]. 
  
            Thank you for your dedication and contributions to [COMPANY_NAME]. 
  
            Best regards, 
  
            [UNIVERSITY_NAME] 
            Bursar`,
          variables: payrollNotifications?.customMessage?.variables?.map(item => ({
            key: item?.key,
            description: item?.description,
          })) || [
            { key: "[EMPLOYEE_NAME]", description: "Name of the employee" },
            { key: "[PAY_PERIOD]", description: "Month or period of payment" },
            { key: "[HR_CONTACT]", description: "HR/Payroll Department Contact" },
            { key: "[COMPANY_NAME]", description: "Name of the company" },
            { key: "[UNIVERSITY_NAME]", description: "Name of the university" },
          ],
        },
      });
    }
  }, [payrollNotifications]);
   

  const validationSchema = Yup.object().shape({
    customMessage: Yup.object().shape({
      content: Yup.string().required("Message content is required"),
    }),
  });

  const handleInsertVariable = (variable, setFieldValue, values) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const cursorPosition = quill.getSelection()?.index || 0;

      const variableText = `${variable}`;
      quill.insertText(cursorPosition, variableText, "bold", true);

      setFieldValue("customMessage.content", quill.root.innerHTML);
      setFieldValue("customMessage.plainText", quill.getText());
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const payload = {
      enable: values.enabled,
      customMessage: {
        content: values.customMessage.content,
        plainText: values.customMessage.plainText,
        variables: values.customMessage.variables.filter((varObj) =>
          values.customMessage.content.includes(varObj.key)
        ), 
      },
    };

    const res = await dispatch(updatePayrollNotification(payload));
    if (res) {
      setSubmitting(false);
    }

    
  };

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Container>
            <FlexRowSpaceBetween>
              <Text size="1.6rem" weight="500" lineHeight="2.8rem">
                Payroll Notifications
              </Text>
              <FlexCentredRow className="gap-[2rem]">
                <Text size="1.4rem" weight="500" lineHeight="2.4rem">
                  {values.enabled ? "Enabled" : "Disabled"}
                </Text>
                <Field
                  name="enabled"
                  render={({ field }) => (
                    <ToggleSwitch
                      checked={field.value}
                      setChecked={(val) => setFieldValue("enabled", val)}
                    />
                  )}
                />
              </FlexCentredRow>
            </FlexRowSpaceBetween>

            <Text size="1.4rem" weight="400" lineHeight="2.4rem" align="left" top="2rem">
              When enabled, employees will receive email notifications whenever payroll
              is created.
            </Text>

            {values.enabled && (
              <>
                <FlexRowSpaceBetween className="my-4">
                  <Text size="1.4rem" weight="500" lineHeight="2.4rem" align="left">
                    Custom Message
                  </Text>

                  {/* Select component for inserting variables */}
                  <Select
                    name="customMessage.variables"
                    objProp="name"
                    placeholder="Insert Variable"
                    data={values?.customMessage?.variables?.map((item) => ({
                      name: item?.description,
                      value: item?.key,
                    })) || []} 
                    onChange={(selected) => {
                      if (selected) {
                        handleInsertVariable(selected[0].value, setFieldValue, values);
                      }
                    }}
                  />
                </FlexRowSpaceBetween>

                {/* ReactQuill Editor */}
                <Field name="customMessage.content">
                  {({ field }) => (
                    <ReactQuill
                      ref={quillRef}
                      value={field.value}
                      onChange={(content, delta, source, editor) => {
                        setFieldValue("customMessage.content", content);
                        setFieldValue("customMessage.plainText", editor.getText());
                      }}
                      theme="snow"
                      className="min-h-[150px]"
                    />
                  )}
                </Field>

                <FlexRowEnd>
                  <Button
                    type="submit"
                    className="border border-gray-300 bg-white mt-[2rem]"
                  >
                    Edit
                  </Button>
                </FlexRowEnd>
              </>
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default PayrollNotification;

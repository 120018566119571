import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FlexCentredCol } from "../../../containers/ScreenContainers";
import { getPayrollSettings } from "../../../redux/bursary/actions";

import PayrollApproval from "./payroll-approval";
import PayrollNotification from "./payroll-notification";
import SalaryBreakdown from "./salary-breakdown";

const PayrollSettings = () => {
  const dispatch = useDispatch()
 const {  payrollSettings } = useSelector((state) => state.bursary);
const {salaryBreakdown, payrollNotifications, payrollApproval} = payrollSettings || {}

const [toggleRefetch, setToggleRefetch] = useState(false);

useEffect(() => {
        dispatch(getPayrollSettings());
    }, [dispatch, toggleRefetch]);


  return (
    <FlexCentredCol className="gap-[1rem]">
      <SalaryBreakdown salaryBreakdown={salaryBreakdown} setToggleRefetch={setToggleRefetch}/>
      <PayrollNotification payrollNotifications={payrollNotifications} setToggleRefetch={setToggleRefetch}/>
      <PayrollApproval payrollApproval={payrollApproval} setToggleRefetch={setToggleRefetch}/>
    </FlexCentredCol>
  );
};



export default PayrollSettings;

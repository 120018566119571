import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../../assets/icons/circlePlus.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import RadioInput from '../../../../components/inputs/radio';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    addStaffToPaymentSubGroup,
    getAllStaffPaymentGroup,
    getPaymentSubGroupsById,
} from '../../../../redux/bursary/actions';

const Container = tw.div`
   
    max-w-[79.3rem]
`;

const AddStaffToSubGroup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state: { groupDetails, SubGroupDetails, stepName },
    } = useLocation();

    const id = SubGroupDetails?._id;

    const { isLoading, staffInPaymentGroup } = useSelector((state) => state.bursary);

    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [staffCategory, setStaffCategory] = useState('platformStaff');
    const [selectedStaff, setSelectedStaff] = useState([]);

    const { staffs: allstaffInPaymentGroup, pagination } = staffInPaymentGroup || {};
    const { total, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const fields = [
        {
            header: 'Staff Name',
            cell: ({ row }) => {
                const { firstName, otherName, surname } = row?.original || {};
                return (
                    <p>
                        {firstName} {otherName || ''} {surname}
                    </p>
                );
            },
        },

        {
            header: 'Staff Number',
            accessorKey: 'staffNumber',
        },
        {
            header: 'Staff Type',
            accessorKey: 'staffType',
        },

        {
            header: 'Department / unit',
            accessorKey: 'subProgram' || 'department',
        },
    ];

    const manualFields = [
        {
            header: 'Staff Name',
            accessorKey: 'staffName',
        },
        {
            header: 'Staff Number',
            accessorKey: 'staffNumber',
        },
        {
            header: 'Staff Type',
            accessorKey: 'staffType',
        },
    ];
    useEffect(() => {
        if (id) {
            dispatch(getPaymentSubGroupsById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
            staffType: staffCategory,
        };
        dispatch(getAllStaffPaymentGroup(query, id));
    }, [dispatch, debouncedSearchValue, staffCategory, currentPage, numOfPages, id]);

    return (
        <Container>
            <GoBack title={`Payment Group / ${groupDetails?.groupName}`} subTitle={stepName}></GoBack>
            <Formik
                initialValues={{
                    staffIds: [],
                    staffType: 'platformStaff',
                }}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    values.staffIds = selectedStaff?.map((staff) => staff?._id);
                    values.staffType = staffCategory;
                    const payload = {
                        staffIds: [...values.staffIds],
                        staffType: values.staffType,
                    };
                    const res = await dispatch(addStaffToPaymentSubGroup(payload, id));
                    if (res) {
                        actions.resetForm();
                        setSelectedStaff([]);
                        navigate(-1);
                    }
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <FlexCentredCol className="bg-white gap-[2.4rem] p-[2.4rem]">
                            <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                <PlusCircle fill="#6366f1" />
                                <Text size="1.6rem" weight="600">
                                    Add Staff
                                </Text>
                            </FlexCentredRow>
                            <Text align="left">
                                Once added, the staff member will be included in the payroll processing for this group
                                and will receive payments according to the group's salary structure, allowances, and
                                deductions.
                            </Text>
                            <Text align="left">
                                Choose staff category to select staff that will be added to the group
                            </Text>
                            <FlexCentredRow className="gap-[2rem]">
                                <RadioInput
                                    name="staffType"
                                    label="Platform Staff"
                                    checked={values?.staffType === 'platformStaff'}
                                    onClick={() => {
                                        setFieldValue('staffType', 'platformStaff');
                                        setStaffCategory('platformStaff');
                                    }}
                                />
                                <RadioInput
                                    name="staffType"
                                    label="Manual Staff"
                                    checked={values?.staffType === 'manualStaff'}
                                    onClick={() => {
                                        setFieldValue('staffType', 'manualStaff');
                                        setStaffCategory('manualStaff');
                                    }}
                                />
                            </FlexCentredRow>
                        </FlexCentredCol>
                        {staffCategory === 'platformStaff' && (
                            <PageLayout
                                data={allstaffInPaymentGroup || []}
                                fields={fields}
                                loading={isLoading}
                                onSearchChange={(e) => setSearchValue(e.target.value)}
                                searchValue={searchValue}
                                onSearchClose={() => setSearchValue('')}
                                showTableUtils
                                isActionable
                                actionType="checkbox"
                                onSelectedItemsChange={(items) => setSelectedStaff(items)}
                                width={'100%'}
                                paginationComponent={
                                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                                        <PaginationStatus
                                            currentPage={currentPage}
                                            itemsPerPage={tableSettings?.numOfPages}
                                            totalItems={total}
                                        />
                                        <Pagination
                                            pageRangeDisplayed={3}
                                            totalPages={totalPages}
                                            onPageChange={(nextPage) => {
                                                setCurrentPage(nextPage);
                                            }}
                                            currentPage={currentPage}
                                        />
                                    </FlexRowSpaceBetween>
                                }
                            />
                        )}
                        {staffCategory === 'manualStaff' && (
                            <PageLayout
                                data={allstaffInPaymentGroup || []}
                                fields={manualFields}
                                loading={isLoading}
                                onSearchChange={(e) => setSearchValue(e.target.value)}
                                searchValue={searchValue}
                                onSearchClose={() => setSearchValue('')}
                                isActionable
                                actionType="checkbox"
                                onSelectedItemsChange={(items) => setSelectedStaff(items)}
                                showTableUtils
                                width={'100%'}
                                paginationComponent={
                                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                                        <PaginationStatus
                                            currentPage={currentPage}
                                            itemsPerPage={tableSettings?.numOfPages}
                                            totalItems={total}
                                        />
                                        <Pagination
                                            pageRangeDisplayed={3}
                                            totalPages={totalPages}
                                            onPageChange={(nextPage) => {
                                                setCurrentPage(nextPage);
                                            }}
                                            currentPage={currentPage}
                                        />
                                    </FlexRowSpaceBetween>
                                }
                            />
                        )}

                        <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={isLoading || selectedStaff.length === 0}
                                bgColor="#6366F1"
                                color="#fff"
                                type="submit"
                            >
                                {selectedStaff.length > 0 ? `Add ${selectedStaff.length} Staff` : 'Add Staff'}
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default AddStaffToSubGroup;

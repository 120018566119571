import React from 'react';

import PageLayout from '../../../../../../layout/page-layout/PageLayout';

const fields = [
    {
        header: 'Pay Date',
        accessorKey: '',
    },
    {
        header: 'Gross Salary',
        accessorKey: '',
    },
    {
        header: 'Net Salary',
        accessorKey: '',
    },
    {
        header: 'Status',
        accessorKey: '',
    },
];

const PayrollHistory = ({ salaryHistory, searchValue, setSearchValue, isLoading }) => {
    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                fields={fields}
                data={salaryHistory || []}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                loading={isLoading}
            />
        </div>
    );
};

export default PayrollHistory;

import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import * as yup from 'yup';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as PlusCircle } from '../../../../assets/icons/circlePlus.svg';
import { Select } from '../../../../components';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import TextInput from '../../../../components/inputs/text-input';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../../containers/ScreenContainers';
import { editManualStaff, getAllBanksFromPaystack, getManualStaffById } from '../../../../redux/bursary/actions';

const ValidationSchema = yup.object().shape({
    staffName: yup.string().required('Staff Name is required'),
    staffEmail: yup.string().email('Invalid email format').nullable(), // Optional field
    staffPhoneNumber: yup
        .string()
        .matches(/^[0-9]{10,15}$/, 'Phone Number must be between 10 and 15 digits')
        .nullable(), // Optional field
    staffAddress: yup.string().nullable(), // Optional field
    staffGender: yup.string().oneOf(['Male', 'Female', 'Other'], 'Invalid gender selection').nullable(), // Optional field
    staffType: yup
        .string()
        .oneOf(['manual'], 'Invalid staff type') // Assuming "manual" is the only valid value
        .required('Staff Type is required'),
    accountName: yup.string().nullable(), // Optional field
    accountNumber: yup
        .string()
        .matches(/^[0-9]{10,12}$/, 'Account Number must be between 10 and 12 digits')
        .required('Account Number is required'),
    accountType: yup
        .string()
        .oneOf(['Savings Account', 'Current Account'], 'Invalid account type')
        .required('Account Type is required'),
    bankName: yup.string().required('Bank Name is required'),
});

const Container = tw.div`
bg-white
h-auto
max-w-[1000px]
px-[3.2rem]
py-[3.6rem]
rounded-[16px]
`;

const EditStaffAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading, staffAccount } = useSelector((store) => store.bursary);

    const [allbanks, setAllBanks] = useState([]);

    useEffect(() => {
        const getBanks = async () => {
            const res = await dispatch(getAllBanksFromPaystack());
            setAllBanks(res);
        };
        getBanks();
    }, [dispatch]);

    useEffect(() => {
        dispatch(getManualStaffById(id));
    }, [dispatch, id]);

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Staff Details
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Add Account
                </PageTitle>
            </FlexCentredRow>

            <Formik
                initialValues={
                    staffAccount || {
                        staffName: '',
                        staffEmail: '',
                        staffPhoneNumber: '',
                        staffAddress: 'Akobo',
                        staffGender: 'Male',
                        staffType: 'manual',
                        accountName: '',
                        accountNumber: '',
                        accountType: '',
                        bankName: '',
                    }
                }
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(editManualStaff(values));
                    if (res) navigate(-1);
                }}
            >
                {({ handleChange, errors, setFieldValue }) => (
                    <Form>
                        <div className="max-w-[900px]">
                            <Container>
                                <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                    <PlusCircle fill="#6366f1" />
                                    <Text size="1.6rem" weight="600">
                                        Add Account
                                    </Text>
                                </FlexCentredRow>
                                <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-1 grid mt-[2.5rem]">
                                    <FlexCentredCol>
                                        <TextInput
                                            name="staffName"
                                            onChange={handleChange}
                                            label="Staff Name"
                                            type="text"
                                            placeholder="Input staff Name"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="staffPhoneNumber"
                                            onChange={handleChange}
                                            label="Phone Number (optional)"
                                            type="text"
                                            placeholder="Input Phone Number"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="staffNumber"
                                            onChange={handleChange}
                                            label="Staff Number (optional)"
                                            type="text"
                                            placeholder="Input staff Number"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="staffEmail"
                                            onChange={handleChange}
                                            label="Email (optional)"
                                            type="text"
                                            placeholder="Input Email"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="accountName"
                                            onChange={handleChange}
                                            label="Bank Account Name (optional)"
                                            type="text"
                                            placeholder="Input Bank Account Name"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="accountNumber"
                                            onChange={handleChange}
                                            label="Account Number"
                                            type="text"
                                            placeholder="Input Account Number"
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <Select
                                            name="bankName"
                                            objProp="name"
                                            data={allbanks || []}
                                            placeholder="Select Bank Name"
                                            searchable
                                            label="Bank Name"
                                            onChange={(selected) => {
                                                setFieldValue('bankName', selected?.[0]?.name);
                                            }}
                                            height="max-h-[10rem]"
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <Select
                                            name="accountType"
                                            objProp="name"
                                            data={[{ name: 'Savings Account' }, { name: 'Current Account' }]}
                                            placeholder="Select Account Type"
                                            searchable
                                            label="Account Type"
                                            onChange={(selected) => {
                                                setFieldValue('accountType', selected?.[0]?.name);
                                            }}
                                            height="max-h-[10rem]"
                                        />
                                    </FlexCentredCol>
                                </div>
                            </Container>
                            <div className="flex items-center gap-[1rem] my-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton isLoading={isLoading} bgColor="#6366F1" color="#fff" type="submit">
                                    Add Account
                                </LoadingButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditStaffAccount;

import React, { useEffect, useState, useMemo } from 'react';

import { format } from 'date-fns';
import { Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowRight } from '../../../../assets/icons/ChevronRight.svg';
import { ReactComponent as PlusCircle } from '../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/people.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowCentered,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import { addDebtor, getAllActivePaymentGateways } from '../../../../redux/bursary/actions';
import { getStudents } from '../../../../redux/students/actions';
import { range } from '../../../../utils';
import { installmentOpt, paymentScheduleData } from '../../../../utils/bursaryData';
import currencyFormatter from '../../../../utils/formatCurrency';

import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
    mt-10
    max-w-[1000px]
    mb-[100px]
`;

const SPAN_OPTIONS = [
    { name: 'First Semester', value: 'first-semester' },
    { name: 'Second Semester', value: 'second-semester' },
    { name: 'Both Semesters', value: 'both-semesters' },
];

const INSTALMENT_PLAN_OPTIONS = [
    { name: 'Percentage Based (Institution sets a ratio percentage)', value: 'percent' },
    { name: 'Student’s Choice (Students determines the amount to pay in each instalment)', value: 'student' },
];

const TYPE_OF_FEE = [
    {
        name: 'Tuition Fee',
        value: 'Tuition-Fee',
    },
];

const calculateTotalBreakdown = (feeBreakdown) => {
    let totalAmount = 0;
    feeBreakdown.forEach((fee) => {
        totalAmount += parseFloat(fee.amount) || 0;
    });
    return totalAmount;
};

const AddDebtor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { sessions } = useSelector((store) => store.admission);
    const { isLoading } = useSelector((store) => store.bursary);
    const { students = [] } = useSelector((store) => store.student);

    const [activePaymentGateways, setActivePaymentGateways] = useState([]);
    const [showBreakdown, setShowBreakdown] = useState(false);

    const [uniquePaymentProviders, setUniquePaymentProviders] = useState([]);

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);

    const isSubmitDisabled = (feeBreakDownObj, totalOwed) => {
        const totalBreakdown = calculateTotalBreakdown(feeBreakDownObj);

        if (isLoading || (totalBreakdown > 0 && totalBreakdown !== totalOwed)) return true;
        return false;
    };

    useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllActivePaymentGateways());
            const uniqueProviders = data?.filter(
                (item, index, self) => index === self?.findIndex((t) => t?.provider === item?.provider),
            );
            setActivePaymentGateways(data);
            setUniquePaymentProviders(uniqueProviders);
        };

        getActiveGateways();
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStudents());
    }, [dispatch]);

    return (
        <>
            <GoBack title="Debt Management" subTitle="Add Debtor" />

            <Container>
                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Add Debtor
                    </Text>
                </FlexCentredRow>

                <Formik
                    initialValues={{
                        search: '',
                        session: '',
                        //feeName
                        feeType: '',
                        //description
                        amountOwed: '',
                        span: '',
                        paymentConfig: '',
                        deadline: '',
                        debtSchedule: '',
                        numberOfInstallments: '',
                        ratios: '',
                        deadlines: [''],
                        studentDecides: '',
                        feeBreakdown: [
                            // {
                            //     amount: '',
                            //     description: '',
                            // },
                        ],
                        businessName: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        let deadlines = [],
                            ratios = [];

                        const { deadline1, deadline2, deadline3, deadline4, deadline5, ...otherValues } = values;

                        if (values.debtSchedule === 'Installment') {
                            const dates = [deadline1, deadline2, deadline3, deadline4, deadline5];

                            deadlines = dates.filter((item) => item).map((item) => format(item, 'yyyy-MM-dd'));
                        }

                        if (values.debtSchedule === 'Installment' && values.studentDecides === 'percent') {
                            ratios = values.ratios.split(':').map((item) => Number(item));
                        }

                        const payload = {
                            ...otherValues,
                            deadline: values.deadline ? format(values.deadline, 'yyyy-MM-dd') : '',
                            deadlines,
                            ratios,
                            studentDecides: values.studentDecides === 'percent' ? false : true,
                        };
                        const res = await dispatch(addDebtor(payload));

                        if (res) navigate(-1);
                    }}
                >
                    {({ handleChange, errors, values, setFieldValue, touched }) => (
                        <Form>
                            <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid">
                                <Select
                                    searchable
                                    name="search"
                                    objProp="fullName"
                                    data={acceptedStudents?.map((item) => ({
                                        ...item,
                                        fullName: `${item.matricNumber ? item.matricNumber + ' -' : ''} ${
                                            item.lastName
                                        } ${item.firstName} ${item.middleName}`,
                                    }))}
                                    placeholder="Student Name"
                                    label="Student Name"
                                    onChange={(selected) => {
                                        setFieldValue('search', selected[0].studentEmail);
                                    }}
                                    error={errors.search}
                                />
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    data={sessions}
                                    placeholder="Choose Session"
                                    label="Session"
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                    }}
                                    error={errors.session}
                                />

                                <TextInput
                                    name="feeName"
                                    onChange={handleChange}
                                    label="Name of Fee (Optional)"
                                    type="text"
                                    placeholder="Input name of Fee"
                                    errors={errors}
                                />

                                <Select
                                    name="feeType"
                                    placeholder="Choose Fee Type"
                                    objProp="name"
                                    data={TYPE_OF_FEE}
                                    label="Type of Fee"
                                    onChange={(selected) => {
                                        setFieldValue('feeType', selected[0].value);
                                    }}
                                    error={errors.feeType}
                                />

                                <TextInput
                                    name="description"
                                    label="Description (Optional)"
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Input description"
                                    errors={errors}
                                />

                                <TextInput
                                    name="amountOwed"
                                    type="number"
                                    label="Amount Owed (₦)"
                                    placeholder="Input Amount"
                                    onChange={handleChange}
                                    errors={errors}
                                />

                                <Select
                                    name="span"
                                    objProp="name"
                                    label="Span of Fee"
                                    placeholder="Choose Span"
                                    data={SPAN_OPTIONS}
                                    onChange={(selected) => {
                                        setFieldValue('span', selected[0].value);
                                    }}
                                    error={errors.span}
                                />

                                <Select
                                    name="paymentConfig"
                                    objProp="provider"
                                    label="Payment Gateway"
                                    placeholder="Choose a Payment Gateway"
                                    data={uniquePaymentProviders}
                                    onChange={(selected) => {
                                        setFieldValue('paymentConfig', selected[0]?.provider);
                                        // setPaymentConfig(selected[0].provider);
                                    }}
                                    error={errors.paymentConfig}
                                />

                                {values?.paymentConfig && (
                                    <FlexCentredCol>
                                        <Select
                                            name="businessName"
                                            objProp="businessName"
                                            label="Business Name"
                                            placeholder="Choose a Business Name"
                                            data={activePaymentGateways?.filter(
                                                (item) =>
                                                    item?.provider?.toLowerCase() ===
                                                    values?.paymentConfig?.toLowerCase(),
                                            )}
                                            onChange={(selected) => {
                                                setFieldValue('businessName', selected[0]?.businessName);
                                            }}
                                            error={errors.paymentConfig}
                                        />
                                    </FlexCentredCol>
                                )}

                                <Select
                                    name="debtSchedule"
                                    objProp="name"
                                    placeholder="Select a Payment Schedule"
                                    label="Payment Schedule"
                                    data={paymentScheduleData}
                                    onChange={(selected) => {
                                        setFieldValue('debtSchedule', selected[0].name);
                                        setFieldValue('deadline', '');
                                        setFieldValue('numberOfInstallments', '');
                                        setFieldValue('studentDecides', '');
                                        setFieldValue('ratios', '');
                                    }}
                                    error={errors.debtSchedule}
                                />

                                {values.debtSchedule === 'One-Off' && (
                                    <DatePicker
                                        label="Deadline (Optional)"
                                        name="deadline"
                                        touched={touched}
                                        minDate={new Date()}
                                    />
                                )}

                                {values.debtSchedule === 'Installment' && (
                                    <>
                                        <Select
                                            name="numberOfInstallments"
                                            objProp="name"
                                            label="Number of Instalments"
                                            placeholder="Select number of instalments"
                                            data={installmentOpt.map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('numberOfInstallments', Number(selected[0].name));
                                            }}
                                            error={errors.numberOfInstallments}
                                        />

                                        <Select
                                            name="studentDecides"
                                            objProp="name"
                                            label="Instalment Plan"
                                            placeholder="Select instalment plan"
                                            data={INSTALMENT_PLAN_OPTIONS}
                                            onChange={(selected) => {
                                                setFieldValue('studentDecides', selected[0].value);
                                            }}
                                            error={errors.studentDecides}
                                        />
                                    </>
                                )}

                                {values.debtSchedule === 'Installment' && values.studentDecides === 'percent' && (
                                    <TextInput
                                        name="ratios"
                                        type="text"
                                        label="Instalment Percentage"
                                        placeholder="Input instalment percentage e.g 50:50, 40:30:30"
                                        onChange={handleChange}
                                        errors={errors}
                                    />
                                )}
                                {values.debtSchedule === 'Installment' && (
                                    <>
                                        {range(values.numberOfInstallments)?.map((num) => (
                                            <DatePicker
                                                key={num}
                                                label={`Deadline ${num} (Optional)`}
                                                name={`deadline${num}`}
                                                touched={touched}
                                                minDate={
                                                    num !== 1 ? new Date(values?.[`deadline${num - 1}`]) : new Date()
                                                }
                                            />
                                        ))}
                                    </>
                                )}
                            </div>

                            <div className="">
                                <FlexCentredRow
                                    className="pt-[3.2rem] gap-[1rem] max-w-max cursor-pointer"
                                    onClick={() => setShowBreakdown(!showBreakdown)}
                                >
                                    <Text size="1.6rem" weight="600" align="left">
                                        Fee Breakdown
                                    </Text>
                                    <Text size="1.6rem" weight="400" align="left">
                                        (optional)
                                    </Text>

                                    <ArrowRight className={`${showBreakdown ? 'rotate-90' : ''}`} />
                                </FlexCentredRow>

                                {showBreakdown ? (
                                    <FlexCentredCol className="gap-[5.6rem]">
                                        <FieldArray
                                            name="feeBreakdown"
                                            render={({ insert, remove, push }) => (
                                                <React.Fragment>
                                                    <div className="flex flex-col gap-[1rem]">
                                                        {values.feeBreakdown?.map((fee, index) => (
                                                            <div
                                                                key={index}
                                                                className="grid grid-cols-[1fr_1fr_50px] gap-x-[1.6rem] mt-6"
                                                            >
                                                                <TextInput
                                                                    name={`feeBreakdown.${index}.description`}
                                                                    label="Description"
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    value={fee.description}
                                                                    placeholder="Input Description"
                                                                    errors={errors}
                                                                />
                                                                <TextInput
                                                                    name={`feeBreakdown.${index}.amount`}
                                                                    label="Amount"
                                                                    onChange={handleChange}
                                                                    type="number"
                                                                    value={fee.amount}
                                                                    placeholder="Input Amount"
                                                                    errors={errors}
                                                                />

                                                                <Delete
                                                                    onClick={() => remove(index)}
                                                                    className="cursor-pointer self-end mb-3"
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <FlexRowSpaceBetween>
                                                        <Button
                                                            className="flex items-center gap-[8px]"
                                                            border="1px solid #2563EB"
                                                            type="button"
                                                            color="#2563EB"
                                                            onClick={() => push({ description: '', amount: '' })}
                                                        >
                                                            <PlusCircle fill="#2563EB" />
                                                            Add Fee Breakdown
                                                        </Button>
                                                        <FlexRowCentered className="gap-[2.4rem]">
                                                            <Text align="left" size="1.4rem" weight="500">
                                                                Amount Owed :{' '}
                                                                {currencyFormatter.format(values.amountOwed || 0)}
                                                            </Text>

                                                            <Text align="left" size="1.4rem" weight="500">
                                                                Total Fee Breakdown :{' '}
                                                                {currencyFormatter.format(
                                                                    calculateTotalBreakdown(values.feeBreakdown) || 0,
                                                                )}
                                                            </Text>
                                                        </FlexRowCentered>
                                                    </FlexRowSpaceBetween>
                                                </React.Fragment>
                                            )}
                                        />
                                    </FlexCentredCol>
                                ) : null}
                            </div>

                            <div className="flex items-center gap-[1rem] mt-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isSubmitDisabled(values.feeBreakdown, values.amountOwed)}
                                    type="submit"
                                >
                                    Add Debtor
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default AddDebtor;

import React from 'react';

import Bonus from '../salary-items/bonus';
import Deductions from '../salary-items/deductions';
import SalaryHistory from '../salary-items/salary-history';

const SalaryItems = () => {
    return (
        <div className="mt-3 flex flex-col gap-7">
            <Bonus />
            <Deductions />
            <SalaryHistory />
        </div>
    );
};

export default SalaryItems;

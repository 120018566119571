import React, { useEffect, useState } from 'react';

import { RadioInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ChangeGatewayStatus = ({ show, close, confirmAction, isLoading, btnDisabled, selectedGateway }) => {
    const [enableGateway, setEnableGateway] = useState(false);

    useEffect(() => {
        if (show) {
            setEnableGateway(selectedGateway?.active);
        }
    }, [show, selectedGateway]);
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <div className="">
                <FlexRowEnd>
                    <CloseIcon onClick={close} className="cursor-pointer" />
                </FlexRowEnd>
                <FlexCentredRow className="gap-[1.215rem] mb-4 mt-2">
                    <Text as="h3" size="1.9rem" weight="600">
                        Payment Gateway Status
                    </Text>
                </FlexCentredRow>
            </div>
            <FlexCentredCol className="gap-4">
                <RadioInput checked={enableGateway} onChange={() => setEnableGateway(true)} label="Enable" />
                <RadioInput checked={!enableGateway} onChange={() => setEnableGateway(false)} label="Disable" />
            </FlexCentredCol>

            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    disabled={enableGateway === selectedGateway?.active}
                    onClick={confirmAction}
                    // bgColor={bgColor || '#EF4444'}
                    // color="#fff"
                >
                    Save Changes
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ChangeGatewayStatus;

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import EditFeeForm from '../../../../components/forms/edit-fee';
// import { Loader } from '../../../../components/loader';
import { getFeeById } from '../../../../redux/bursary/actions';

const EditFee = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    // const { fee } = useSelector((store) => store.bursary);
    const [feeDetails, setFeeDetails] = useState(null);

    useEffect(() => {
        const getFeeDetails = async () => {
            const data = await dispatch(getFeeById(id));
            if (data) {
                setFeeDetails(data);
            } else {
                setFeeDetails(null);
            }
        };

        getFeeDetails();
    }, [dispatch, id]);

    return (
        <div>
            <EditFeeForm feeDetails={feeDetails} feeId={id} />
        </div>
    );
};

export default EditFee;

import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_AGENT_DETAILS,
    GET_ALL_AGENT_FEES,
    GET_ALL_AGENTS,
    GET_ALL_BANKS,
    GET_ALL_DEBTORS,
    GET_ALL_DISCOUNTS,
    GET_ALL_FEES,
    GET_ALL_RECEIVING_ACCOUNTS,
    GET_ARCHIVED_STAFFS,
    GET_ALL_STAFF_PAYMENT_GROUP,
    GET_DISCOUNT_DETAILS,
    GET_FEE,
    GET_FEE_DETAILS,
    GET_GROUP_DETAILS,
    GET_FEES_ATTACHED,
    GET_INSTALLMENT_DETAILS,
    GET_MANUAL_STAFFS,
    GET_PAID_DETAILS,
    GET_PAYMENT_GATEWAY,
    GET_PAYMENT_HISTORY,
    GET_PLATFORM_STAFFS,
    GET_STUDENT_PAYMENT_HISTORY,
    GET_STUDENT_RECEIPT,
    GET_SUB_GROUPS,
    GET_SUBGROUP_DETAILS,
    GET_UNPAID_DETAILS,
    LOADING,
    STOP_LOADING,
    GET_PAYMENT_GROUPS,
    GET_PAYROLL_SETTINGS,
} from './slice';

export const addFee = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/fees`, payload);
        const successMessage = response?.data?.data.message || 'Fee Created Successfully!';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllFees = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/fees?${queries}`);
        if (response.status === 200) {
            dispatch(GET_ALL_FEES(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getUngroupedAllFees = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/fees?${queries}`);
        if (response.status === 200) {
            // dispatch(GET_ALL_FEES(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createReceivingAccount = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}payments/:campusId/:unitId/create-receiving-account`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Receiving Account Created Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editReceivingAccount = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/edit-receiving-account/${id}`,
            payload,
        );
        const successMessage = response?.data?.data.message || 'Receiving Account Updated Successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllReceivingAccount = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all-receiving-account`);
        dispatch(GET_ALL_RECEIVING_ACCOUNTS(response.data.accountsWithCounts));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllBanks = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/get-all-banks`);
        dispatch(GET_ALL_BANKS(response.data.banks));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteAccount = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}payments/:campusId/:unitId/delete-receiving-account/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Account Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFeeDetails = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/fees/details/${id}?${queries}`,
        );
        dispatch(GET_FEE_DETAILS(response.data.data));
        return response.data.data;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const publishFee = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/fees/publish/${id}`,
            payload,
        );
        const successMessage = response?.data?.data.message || 'Fee Published Successfully!';
        toast.success(successMessage);
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const unPublishFee = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}payments/:campusId/:unitId/fees/unpublish/${id}`);
        const successMessage = response?.data?.data.message || 'Fee unpublished Successfully!';
        toast.success(successMessage);
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteFee = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/fees/delete/${id}`);
        const successMessage = response?.data.message || 'Fee Deleted Successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPaidFull = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/fees/paidfull/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_PAID_DETAILS(response.data));
            return response.data.data;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getUnpaidFull = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/fees/notpaid/${id}?${queries}`,
        );
        if (response.status === 200) {
            const transformedData = response?.data?.data?.map((data) => ({
                ...data,
                faculty: data?.college || data?.school || data?.faculty,
                amountPayable: response?.data?.amountPayable,
            }));
            dispatch(GET_UNPAID_DETAILS(response.data));
            return transformedData;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getInstallmentPaid = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/fees/notfull/${id}?${queries}`,
        );
        if (response.status === 200) {
            const transformedData = response?.data?.data?.map((data) => ({
                ...data?.studentId,
                faculty: data?.college || data?.school || data?.faculty,
                totalAmountPaid: data?.totalAmountPaid,
                amountExpected: response?.data?.amountPayable,
                amountOutstanding: response?.data?.amountPayable - data?.totalAmountPaid,
            }));
            dispatch(GET_INSTALLMENT_DETAILS(response.data));
            return transformedData;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPaymentHistory = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/payment-history`);
        if (response.status === 200) {
            dispatch(GET_PAYMENT_HISTORY(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getStudentPaymentHistory = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/student-payment-history/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_STUDENT_PAYMENT_HISTORY(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentReceipt = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/student-payment-receipt/${id}`,
        );
        if (response.status === 200) {
            dispatch(GET_STUDENT_RECEIPT(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getFeeById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/fees/${id}`);
        if (response.status === 200) {
            dispatch(GET_FEE(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const searchStudents = (search) => async (dispatch, getState) => {
    try {
        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/get-students?search=${search}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.clear(error);
    }
};

export const getDepartments = (id, name) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const res = await axiosInstance.get(`${currentAPI}faculty/:campusId/:unitId/department/${id}`);
        const data = res.data.data;
        return { name, id, data };
    } catch (error) {
        return error;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editFee = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}payments/:campusId/:unitId/fees/edit/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const configurePaymentGateway = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/add-paystack-keys`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const modifyPaymentGateway = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}payments/:campusId/:unitId/edit/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllActivePaymentGateways = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/find-all-active`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllPaymentGateways = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/find-all-payment-config`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deletePaymentGatewayById = (gatewayId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/delete/${gatewayId}`);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentGatewayById = (id) => async (dispatch, getState) => {
    dispatch(GET_PAYMENT_GATEWAY(null));
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/${id}`);
        if (response.status === 200) {
            dispatch(GET_PAYMENT_GATEWAY(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        dispatch(GET_PAYMENT_GATEWAY(null));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const toggleGatewayStatus = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}payments/:campusId/:unitId/toggle/${id}`);
        if (response.status === 200 || response?.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDebtors = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/debts`);
        if (response.status === 200) {
            dispatch(GET_ALL_DEBTORS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDebtor = (studentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/debts/${studentId}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDebtor = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/debts`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully created');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const modifyDebtor = (payload, debtId, debtorId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}payments/:campusId/:unitId/debts/${debtId}/${debtorId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully updated');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDebtor = (debtId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/debts/${debtId}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully deleted');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDebtorFee = (debtId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/debts/${debtId}`, {
            data: payload,
        });

        if (response.status === 201 || response.status === 200) {
            toast.success('Fee successfully deleted');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDiscounts = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/discounts?${queries}`);
        if (response.status === 200) {
            dispatch(GET_ALL_DISCOUNTS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDiscountDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/discounts/details/${id}`);
        if (response.status === 200) {
            dispatch(GET_DISCOUNT_DETAILS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        dispatch(GET_DISCOUNT_DETAILS([]));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createDiscounts = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/discounts`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully created');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const applyDiscounts = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/discounts/${id}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully applied');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDiscount = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/discounts/${id}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully deleted');
            dispatch(getAllDiscounts());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentsAttachedToDiscount = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/discounts/students/${id}`);

        if (response.status === 201 || response.status === 200) {
            if (response.data.data) {
                return response.data.data;
            } else {
                return [];
            }
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStudentFromDiscount = (studentId, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/discounts/${feeId}/${studentId}`,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully deleted');
            dispatch(getAllDiscounts());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDiscount = (payload, discountId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/discounts/${discountId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully updated');

            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/agents`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent successfully created');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editAgent = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}payments/:campusId/:unitId/agents/${id}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteAgent = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/agents/${id}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent deleted successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllAgents = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/agents?${queries}`);

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_AGENTS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAgentDetails = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/agents/details/${id}?${queries}`,
        );

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_AGENT_DETAILS(response?.data?.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const attachFeeToAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/agents/attach-fee`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Fee attached successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllAgentFee = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/agents/all-fees`, payload);

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_AGENT_FEES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const moveStudentToSession = (session, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}admission/:campusId/:unitId/move_student/${session}`,
            payload,
        );
        if (response.status === 201 || response.status === 200) {
            toast.success('Student moved successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const attachStudentToAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}admission/:campusId/:unitId/agents/assign_student`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) attached to agent successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const detachStudentFromAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}admission/:campusId/:unitId/agents/detach_student`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) detached from agent successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const attachStudentToFee = (payload, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/fees/add-student/${feeId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) attached to fee successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllBanksFromPaystack = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${process.env.REACT_APP_PAYSTACK_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            },
        });
        const parsedResponse = await response.json();

        return parsedResponse?.data;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const removeStudentFromFee = (payload, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/fees/remove-student/${feeId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student removed from fee successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentGroups = (query) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}paymentgroup/:campusId/:unitId/get_payment_groups?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_PAYMENT_GROUPS(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentGroupsById = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}paymentgroup/:campusId/:unitId/get_payment_group/${id}?${queries}`,
        );

        if (response.status === 200) {
            dispatch(GET_GROUP_DETAILS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPaymentGroup = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/create_payment_group`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Payment Group successfully created');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const detachAgentFromFee = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}payments/:campusId/:unitId/agents/dettach-fee`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Student removed from fee successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editPaymentGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}paymentgroup/:campusId/:unitId/edit_payment_group/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Payment Group updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deletePaymentGroup = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}paymentgroup/:campusId/:unitId/delete_payment_group/${id}`,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Payment Group deleted successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentSubGroups = (query, id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}paymentgroup/:campusId/:unitId/get_subgroups/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_SUB_GROUPS(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentSubGroupsById = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}paymentgroup/:campusId/:unitId/get_subgroup/${id}?${queries}`,
        );

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_SUBGROUP_DETAILS(response.data.data));
            return true;
        }
        if (response.status === 201 || response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPaymentSubGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/create_subgroup/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editPaymentSubGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}paymentgroup/:campusId/:unitId/edit_subgroup/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deletePaymentSubGroup = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}paymentgroup/:campusId/:unitId/delete_subgroup/${id}`,
        );

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addStaffToPaymentGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/add_staff_to_payment_group/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff added to payment group successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addStaffToPaymentSubGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/add_staff_to_subgroup/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff added to payment sub-group successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStaffToPaymentGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/remove_staff_from_payment_group/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff added to payment group successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const removeStaffFromPaymentSubGroup = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}paymentgroup/:campusId/:unitId/remove_staff_from_subgroup/${id}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff removed from payment sub-group successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllStaffPaymentGroup = (query) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}paymentgroup/:campusId/:unitId/get_all_staffs_payment_group?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_ALL_STAFF_PAYMENT_GROUP(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getFeesAttachedToAgent = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/agents/${id}`);

        if (response.status === 201 || response.status === 200) {
            const transformedData = response?.data?.data?.map((item) => ({
                ...item,
                feeName: item?.allFees?.[0]?.feeName,
                feeId: item?._id,
            }));

            dispatch(GET_FEES_ATTACHED(transformedData));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const updatePayrollApproval = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}payroll-settings/:campusId/:unitId/update_payroll_approval`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Payroll Approval updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const updatePayrollNotification = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}payroll-settings/:campusId/:unitId/update_payroll_notifications`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Payroll notification sent successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const updatePayrollSalaryBreakdown = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}payroll-settings/:campusId/:unitId/update_payroll_salary_breakdown`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Salary breakdown updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPayrollSettings = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}payroll-settings/:campusId/:unitId/get_payroll_settings`,
        );
        if (response.status === 200) {
            dispatch(GET_PAYROLL_SETTINGS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
// staff details
export const getManualStaffs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/platform/manual`);
        if (response.status === 200) {
            dispatch(GET_MANUAL_STAFFS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPlatformStaffs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/platform`);
        if (response.status === 200) {
            dispatch(GET_PLATFORM_STAFFS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getArchivedStaffs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/all/archive`);
        if (response.status === 200) {
            const data = response.data.data;
            const manualStaffs = data?.manualStaff;
            const normalStaff = data?.normalStaff;
            const modifiedManualStaff = manualStaffs?.map((staff) => {
                return {
                    ...staff,
                    type: 'manual',
                };
            });
            const modifiedNormalStaff = normalStaff?.map((staff) => {
                return {
                    ...staff,
                    type: 'normal',
                };
            });
            const mergedData = [...modifiedManualStaff, ...modifiedNormalStaff];
            dispatch(GET_ARCHIVED_STAFFS(mergedData));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const addManualStaff = (payload, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/manuals`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff added successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getManualStaffById = (reqId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/manuals/${reqId}`);

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editManualStaff = (reqId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}payments/:campusId/:unitId/manuals/${reqId}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const archiveStaffById = (staffID, staffType) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}payments/:campusId/:unitId/archive/${staffID}/${staffType}`,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Staff archived successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getArchiveStaffById = (staffID, staffType) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}payments/:campusId/:unitId/archive/${staffID}/${staffType}`,
        );

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
//SalaryInformation
export const getStaffSalaryInfoById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/salary/${id}`);

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editStaffSalaryInfoById = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}payments/:campusId/:unitId/salary/${id}`, payload);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteStaffSalaryInfoById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/salary/${id}`);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getStaffSalaryChangeLog = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/salary/history/${id}`);

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
//Bonus
export const addBonusToSalaryItem = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/:campusId/:unitId/bonus/${staffId}`, payload);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editSalaryItemBonus = (bonusId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}payments/:campusId/:unitId/bonus/${bonusId}`, payload);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteSalaryItemBonus = (bonusId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/bonus/${bonusId}`);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getStaffBonus = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/bonus/${staffId}`);

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
//Deduction
export const addDeductionToSalaryItem = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}payments/:campusId/:unitId/deduction/${staffId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editSalaryDeduction = (deductionId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}payments/:campusId/:unitId/deduction/${deductionId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteSalaryDeduction = (deductionId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}payments/:campusId/:unitId/deduction/${deductionId}`);

        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getStaffSalaryDeduction = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/:campusId/:unitId/deduction/${staffId}`);

        if (response.status === 201 || response.status === 200) {
            return response?.data?.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

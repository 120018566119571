import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_ALL_CAMPUS,
    GET_ARCHIVE_STAFFS,
    GET_ORGANIZATION_PROFILE,
    GET_ORGANIZATION_STAFFS,
    LOADING,
    STOP_LOADING,
} from './slice';

export const createCampus = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}campus/create_campus`, payload);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message || 'Campus Created Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const updateCampus = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}campus/edit_campus/${id}`, payload);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message || 'Campus Updated Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteCampus = (campusId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}campus/delete_campus/${campusId}`);
        if (response.status === 201 || response.status === 200) {
            const campusIdInStorage = sessionStorage.getItem('campusId');
            if (campusIdInStorage === campusId) {
                sessionStorage.removeItem('campusId');
            }

            toast.success(response?.data?.message || 'Campus Deleted Successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllCampus = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}campus/get_all_campuses`);
        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_CAMPUS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const updateAdminInfo = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}update_admin_info`, payload, headers);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteOrganization = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}delete_organization_account`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Account Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getOrganizationProfile = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}organization_profile`);
        if (response.status === 200) {
            dispatch(GET_ORGANIZATION_PROFILE(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getOrganizationStaffs = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}organization/get_all_organiation_staff?${queries}`);
        if (response.status === 200) {
            dispatch(GET_ORGANIZATION_STAFFS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createOrganizationStaff = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}organization/create_organiation_staff`, payload);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editOrganizationStaff = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}organization/edit_organiation_staff/${id}`, payload);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const inviteOrganizationStaff = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}organization/invite_staff/${id}`);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const revokeOrganizationStaff = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}organization/revoke_staff/${id}`);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const archiveOrganizationStaff = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}organization/archive_staff/${id}`);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const reinstateOrganizationStaff = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}organization/reinstate_staff/${id}`);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteOrganizationStaff = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}organization/archive_staff/${id}`);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getOrganizationArchiveStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}organization/all_archive_staffs?${queries}`);
        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ARCHIVE_STAFFS(response?.data?.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit-black.svg';
import { ReactComponent as Send } from '../../assets/icons/send-black.svg';
import { ReactComponent as SendGreen } from '../../assets/icons/send-green.svg';
import { ConfirmActionDialogue } from '../../components';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getActiveSession, getAdmissionSessions } from '../../redux/admissions/actions';
import {
    deleteFee,
    // getFeeDetails,
    publishFee,
    unPublishFee,
    getUngroupedAllFees,
} from '../../redux/bursary/actions';
import { capitalizeFirstLetter, getStatusBadge } from '../../utils';
import currencyFormatter from '../../utils/formatCurrency';
import { storeUnit } from '../../utils/originUnit';

const fields = [
    {
        cell: (props) => (
            <Link
                to={`/bursary/fees/fee-details/${props.row.original._id}`}
                state={{ currentFee: props.row.original }}
                style={{ color: '#2563EB' }}
            >
                {props.getValue()}
            </Link>
        ),
        header: 'Fee Name',
        accessorKey: 'feeName',
    },
    {
        cell: (props) => <p>{props.getValue().replace('-', ' ')}</p>,
        header: 'Fee Type',
        accessorKey: 'feeType',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Total No of Students',
        accessorKey: 'totalStudents',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'No of Students(Paid)',
        accessorKey: 'paidStudents',
    },
    {
        cell: ({ row }) => <p>{row.original.totalStudents - row.original.paidStudents}</p>,
        header: 'No of Students(Unpaid)',
        accessorKey: 'unpaidStudents',
    },
    {
        cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
        header: 'Amount',
        accessorKey: 'amountPayable',
    },
    {
        cell: ({ row }) => <p>{currencyFormatter.format(row.original.amountPayable * row.original.totalStudents)}</p>,
        header: 'Amount Expected',
        accessorKey: 'totalAmount',
    },
    {
        cell: (props) => <p>{capitalizeFirstLetter(props.getValue())}</p>,
        header: 'Receiving Accounts',
        accessorKey: 'paymentConfig',
    },
    {
        cell: (props) => <p>{getStatusBadge(capitalizeFirstLetter(props.getValue()))}</p>,
        header: 'Status',
        accessorKey: 'publishStatus',
    },
    {
        cell: (props) => (
            <p>
                {props.getValue()
                    ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                          props.getValue(),
                      ).toLocaleTimeString()}`
                    : ''}
            </p>
        ),
        header: 'Created At',
        accessorKey: 'createdAt',
    },
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Published At',
        accessorKey: 'datePublished',
    },
];

const transformFees = (data) =>
    data.flatMap((item) => {
        const { allFees, ...rest } = item;
        return allFees.map((fee) => ({
            ...fee,
            ...rest,
        }));
    });

const Bursary = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((store) => store.bursary);
    const { sessions, activeSession } = useSelector((store) => store.admission);

    const [fees, setFees] = useState([]);
    const [session, setSession] = useState(activeSession);
    const [openModal, setOpenModal] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const [confirmInputValue, setConfirmInputValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const handleActionSuccess = () => {
        setConfirmInputValue('');
        setOpenModal(false);
        setActionDropdownOpen(false);
        setRefetch((prev) => !prev);
    };

    const handlePublish = async () => {
        const payload = { feeName: confirmInputValue };
        const res = await dispatch(publishFee(payload, selectedItem?._id));
        if (res) {
            handleActionSuccess();
        }
    };

    const handleDeleteFee = async () => {
        if (confirmInputValue?.toLowerCase() === 'delete') {
            const res = await dispatch(deleteFee(selectedItem?._id));
            if (res) {
                handleActionSuccess();
            }
        } else {
            toast.error('Input the correct fee name');
        }
    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
    };

    const handleUnpublish = async () => {
        const res = await dispatch(unPublishFee(selectedItem?._id));
        if (res) {
            handleActionSuccess();
        }
    };

    const handleCloseDialogue = () => {
        setConfirmInputValue('');
        setOpenModal(false);
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.bursarySearch = debouncedSearchValue;
        }
        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [debouncedSearchValue, session]);

    useEffect(() => {
        const getFees = async () => {
            const data = await dispatch(getUngroupedAllFees(query));
            if (data) {
                const transformedData = transformFees(data);
                setFees(transformedData);
            } else {
                setFees([]);
            }
        };
        getFees();
    }, [dispatch, query, refetch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <PageLayout
                pageTitle="Fees"
                secondaryButtonText="Add Fee"
                onSecondaryButtonClick={() => navigate('/bursary/fees/add-fee')}
                onActionBtnClick={() => {
                    setActionDropdownOpen(!actionDropdownOpen);
                }}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={fees || []}
                fields={fields}
                loading={isLoading}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => handleSelectItem(items[0])}
                sessionItems={sessions?.map((session) => ({
                    name: session?.admissionYear,
                    click: () => {
                        setSession(session?.admissionYear);
                        setOpenModal(null);
                    },
                }))}
                sessionValue={session}
                openSessionFilter={openModal === 'session'}
                onSessionFilterClick={() => setOpenModal('session')}
                closeSessionFilter={() => setOpenModal(null)}
                actionOpen={actionDropdownOpen}
                actionClose={() => setActionDropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Send />,
                        name: 'Publish Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => setOpenModal('publish'),
                    },
                    {
                        icon: <CloseIcon />,
                        name: 'Unpublish Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() !== 'published',
                        click: () => setOpenModal('unpublish'),
                    },
                    {
                        icon: <Edit />,
                        name: 'Edit Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => navigate(`/bursary/fees/edit-fee/${selectedItem?._id}`),
                    },
                    {
                        icon: <Delete />,
                        name: 'Delete Fee',
                        disabled: !selectedItem?._id || selectedItem?.publishStatus?.toLowerCase() === 'published',
                        click: () => setOpenModal('delete'),
                    },
                ]}
            />

            <ConfirmActionDialogue
                input
                placeholder="Input publish"
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                show={openModal === 'publish'}
                svgIcon={<SendGreen />}
                title="Publish Payment"
                close={() => handleCloseDialogue()}
                subtitle="This Payment will be published on the student’s portal."
                bodyText="To confirm, input 'publish' in the text field below."
                btn2Text="Yes, Publish"
                bgColor={'#10B981'}
                confirmAction={handlePublish}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'publish'}
            />
            <ConfirmActionDialogue
                input
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                show={openModal === 'unpublish'}
                svgIcon={<CloseIcon />}
                title="Unpublish Payment"
                placeholder="Input unpublish"
                close={() => handleCloseDialogue()}
                subtitle="This Payment will be unpublished on the student’s portal."
                bodyText="To confirm, input 'unpublish' in the text field below."
                btn2Text="Yes, Unpublish"
                bgColor={'red'}
                isLoading={isLoading}
                confirmAction={handleUnpublish}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'unpublish'}
            />
            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => handleCloseDialogue()}
                title="Delete this fee?"
                placeholder="Input delete"
                subtitle="This fee alongside its contents and history will be deleted totally."
                borderLine
                bodyText="To confirm deletion, enter the 'delete' in the text field below."
                input
                noStyle
                value={confirmInputValue}
                onChange={(e) => setConfirmInputValue(e.target.value)}
                btn2Text="Yes, Delete"
                bgColor={'#EF4444'}
                confirmAction={handleDeleteFee}
                isLoading={isLoading}
                btnDisabled={confirmInputValue?.toLowerCase() !== 'delete'}
            />
        </>
    );
};

export default Bursary;

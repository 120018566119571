import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-new.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ConfirmActionDialogue } from '../../../../components';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    archiveStaffById,
    getArchivedStaffs,
    getManualStaffs,
    getPlatformStaffs,
} from '../../../../redux/bursary/actions';

import Archive from './tabs/Archive';
import Manual from './tabs/Manual';
import Platform from './tabs/Platform';

const PaymentGroupStaffDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, manualStaffs, platformStaffs, archivedStaffs } = useSelector((store) => store.bursary);

    const [openModal, setOpenModal] = useState(null);
    const [activeTab, setActiveTab] = useState('platform');
    const [searchValue, setSearchValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        dispatch(getManualStaffs());
        dispatch(getPlatformStaffs());
        dispatch(getArchivedStaffs());
    }, [dispatch, refetch]);

    const handleAfterAction = () => {
        setSelectedItem(null);
        setInputValue('');
        setOpenModal(null);
        setRefetch(!refetch);
    };

    const handleArchiveStaff = async () => {
        const res = await dispatch(archiveStaffById(selectedItem?._id, activeTab === 'platform' ? 'normal' : 'manual'));
        if (res) {
            handleAfterAction();
        }
    };

    return (
        <div>
            <PageLayout
                pageTitle="Staff Details"
                secondaryButtonText="Add Account"
                onSecondaryButtonClick={() => navigate('add-account')}
                actionItems={[
                    {
                        icon: <EditIcon fill="#1f2937" />,
                        name: 'Edit Account',
                        click: () => navigate(`edit-account/${selectedItem?._id}`),
                        disabled: activeTab === 'archived' || activeTab === 'platform' || !selectedItem,
                    },
                    {
                        icon: <DownloadIcon />,
                        name: 'Download Sheet',
                        click: () => {
                            console.log('send-invite');
                        },
                        disabled: !selectedItem,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Archive Account',
                        click: () => setOpenModal('archive-staff'),
                        disabled: activeTab === 'archived' || activeTab === 'platform' || !selectedItem,
                    },
                ]}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                tabComponent={
                    <div className="mt-[3rem]">
                        <CTabs>
                            <CNav variant="tabs" className="">
                                <CNavItem onClick={() => setActiveTab('active')}>
                                    <CNavLink>Platform</CNavLink>
                                </CNavItem>
                                <CNavItem onClick={() => setActiveTab('manual')}>
                                    <CNavLink>Manual</CNavLink>
                                </CNavItem>
                                <CNavItem onClick={() => setActiveTab('archived')}>
                                    <CNavLink>Archive</CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent>
                                <CTabPane>
                                    <Platform
                                        platformStaffs={platformStaffs}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        setSelectedItems={setSelectedItem}
                                        isLoading={isLoading}
                                    />
                                </CTabPane>
                                <CTabPane>
                                    <Manual
                                        manualStaffs={manualStaffs}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        setSelectedItems={setSelectedItem}
                                        isLoading={isLoading}
                                    />
                                </CTabPane>
                                <CTabPane>
                                    <Archive
                                        archiveStaffs={archivedStaffs}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        setSelectedItems={setSelectedItem}
                                        isLoading={isLoading}
                                    />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </div>
                }
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="archive account"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'archive-staff'}
                title="Archive Account"
                close={() => setOpenModal(null)}
                subtitle="[staff Name] account will be archived."
                bodyText="To confirm, type Archive in the text field."
                btn2Text="Yes, Archive"
                bgColor={'#ef4444'}
                confirmAction={handleArchiveStaff}
            />
        </div>
    );
};

export default PaymentGroupStaffDetails;

import React from 'react';

import { useNavigate } from 'react-router-dom';

import PageLayout from '../../../../../layout/page-layout/PageLayout';

const Archive = ({ archiveStaffs, setSelectedItems, setSearchValue, isLoading, searchValue }) => {
    const navigate = useNavigate();

    const fields = [
        {
            header: 'Staff Name',
            accessorKey: 'staffName',
            cell: ({ row }) => (
                <p
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() => navigate(`${row?.original?._id}?staff=archive`, { state: row?.original?.type })}
                >
                    {row?.original?.staffName}
                </p>
            ),
        },
        {
            header: 'Email',
            accessorKey: 'staffEmail',
        },
        {
            header: 'Account Number',
            accessorKey: 'accountNumber',
        },
        {
            header: 'Bank Name',
            accessorKey: 'bankName',
        },
        {
            header: 'Account Type',
            accessorKey: 'accountType',
        },
        {
            header: 'Payment Group',
            accessorKey: 'paymentGroup',
        },
        {
            header: 'Monthly Gross',
            accessorKey: 'monthlyGross',
            cell: (props) => <p>{props.row?.original?.salary?.amount}</p>,
        },
        {
            header: 'Monthly Net',
            accessorKey: 'monthlyNet',
            cell: (props) => <p>{props.row?.original?.salary?.amount}</p>,
        },
        {
            header: 'Currency',
            accessorKey: 'currency',
            cell: (props) => <p>{props.row?.original?.salary?.currency}</p>,
        },
        {
            header: 'Date Created',
            accessorKey: 'createdAt',
            cell: (props) => (
                <p>
                    {props.getValue()
                        ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                              props.getValue(),
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
    ];

    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                fields={fields}
                data={archiveStaffs || []}
                onSelectedItemsChange={(items) => setSelectedItems(items[0])}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                isActionable
                actionType="radio"
                loading={isLoading}
            />
        </div>
    );
};

export default Archive;

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as PlusCircle } from '../../../../../../../../assets/icons/circlePlus.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../assets/icons/edit.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../../../../components';
import { Button } from '../../../../../../../../components/buttons';
import { Text } from '../../../../../../../../containers/MesssageContainers';
import { FlexCentredRow, RelativeContainer } from '../../../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../../../layout/page-layout/PageLayout';
import { deleteSalaryDeduction, getStaffSalaryDeduction } from '../../../../../../../../redux/bursary/actions';

import AddDeduction from './add-deduction';
import EditDeduction from './edit-deduction';

const fields = [
    {
        header: 'Name',
        accessorKey: '',
    },
    {
        header: 'Purpose/Description',
        accessorKey: '',
    },
    {
        header: 'Amount',
        accessorKey: '',
    },
    {
        header: '',
        accessorKey: 'action',
    },
];

const Deductions = ({ isLoading }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [staffDeduction, setStaffDeduction] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const fetchStaffDeduction = async () => {
        const res = await dispatch(getStaffSalaryDeduction(id));
        setStaffDeduction(res);
    };

    useEffect(() => {
        fetchStaffDeduction();
        //eslint-disable-next-line
    }, [refetch]);

    const handleDeleteDeduction = async () => {
        const res = await dispatch(deleteSalaryDeduction(selectedItem?._id));
        if (res) {
            setRefetch(!refetch);
            setOpenModal(null);
        }
    };
    return (
        <div className="bg-white">
            <div className="border-b border-b-solid border-b-[#E5E7EB] py-[8px] px-[32px]">
                <Text align="left" weight="600" size="1.6rem">
                    Deductions
                </Text>
            </div>
            <PageLayout
                noMargin
                fields={fields}
                data={staffDeduction || []}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                loading={isLoading}
                isActionable
                onSelectedItemsChange={(item) => setSelectedItem(item?.[0])}
                actionType="radio"
                tableUtilsChildren={
                    <FlexCentredRow className="gap-3">
                        <Button border="0.5px solid #E5E7EB" onClick={() => setOpenModal('add-deduction')}>
                            <PlusCircle fill="#1F2937" />
                            <Text size="1.5rem" weight="500">
                                Add Deductions
                            </Text>
                        </Button>
                        <RelativeContainer>
                            <Button
                                bgColor="#6366F1"
                                disabled={!selectedItem}
                                color="#fff"
                                onClick={() => setOpenModal('actions')}
                            >
                                Actions
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <EditIcon fill="#1f2937" />,
                                        name: 'Edit Deduction',
                                        click: () => setOpenModal('edit-deduction'),
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Delete Deduction',
                                        click: () => setOpenModal('delete-deduction'),
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                }
            />
            <AddDeduction
                show={openModal === 'add-deduction'}
                close={() => setOpenModal(null)}
                refetch={() => setRefetch(!refetch)}
                staffId={id}
            />
            <EditDeduction
                editIcon={<EditIcon fill="#6366f1" />}
                show={openModal === 'edit-deduction'}
                close={() => setOpenModal(null)}
                refetch={() => setRefetch(!refetch)}
                deductionData={selectedItem}
            />
            <ConfirmActionDialogue
                show={openModal === 'delete-deduction'}
                close={() => setOpenModal(null)}
                title={'Delete Deduction'}
                subtitle={'Are you sure you want to delete this permanently?'}
                btn2Text={'Yes, Delete'}
                confirmAction={handleDeleteDeduction}
            />
        </div>
    );
};

export default Deductions;

import React, { useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'; 
import { SearchInput } from '../../../../components';
import { LoadingButton } from '../../../../components/buttons';
import RadioInput from '../../../../components/inputs/radio';
import ToggleSwitch from '../../../../components/inputs/switch';
import {  Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import { updatePayrollApproval } from '../../../../redux/bursary/actions';
import { getAllStaff } from '../../../../redux/staff/actions';
import { capitalizeFirstLetter } from '../../../../utils';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
`;

const PayrollApproval = ({payrollApproval, setToggleRefetch}) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const dropdownRef = useRef(null);
    // const {  isLoading } = useSelector((state) => state.bursay);
    const { allStaff } = useSelector((state) => state.staff);
    const [staffList, setStaffList] = useState([]); 
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const [dropdown, setDropdown] = useState(false);
     const [enabled, setEnabled] = useState(false);
     const [typeOfApproval, setTypeOfApproval] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);


    useEffect(() => {
    if (payrollApproval?.length > 0) {
      setSelectedApprovers(payrollApproval);
    }
    }, [payrollApproval]);



    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchValue(query);
    };

    const handleToggleEnabled = () => {
    setEnabled(!enabled);
    };

    const handleAddStaff = (staff, setFieldValue) => {
        if (!selectedApprovers.some((s) => s?._id === staff?._id)) {
            const newSelectedApprovers = [...selectedApprovers, staff];
            setSelectedApprovers(newSelectedApprovers);
            setFieldValue('selectedApprovers', newSelectedApprovers);
        }
        setSearchValue('');
        setStaffList([]); 
        setDropdown(false);
    };

    const handleRemoveStaff = (staffId, setFieldValue) => {
        const updatedSelectedApprovers = selectedApprovers.filter((s) => s?._id !== staffId);
        setSelectedApprovers(updatedSelectedApprovers);
        setFieldValue('selectedApprovers', updatedSelectedApprovers);
    };

    useEffect(() => {
        if (!debouncedSearchValue) {
            setSearchValue('');
            setStaffList([]);
            setDropdown(false);
            return;
        }

        dispatch(getAllStaff(debouncedSearchValue));
        setDropdown(true);
    }, [debouncedSearchValue, dispatch]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdown(false);
                setSearchValue('');
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (allStaff) {
            const filteredStaff = allStaff?.filter(
                (staff) =>
                    staff?.firstName.toLowerCase().includes(debouncedSearchValue) ||
                    staff?.surname.toLowerCase().includes(debouncedSearchValue) ||
                    staff?.staffEmail.includes(debouncedSearchValue),
            );
            setStaffList(filteredStaff);
        }
    }, [allStaff, debouncedSearchValue]);


    return (
        <>
            <Container>
            <FlexRowSpaceBetween>
            <Text size='1.6rem' weight='500' lineHeight='2.8rem'>Payroll Approval</Text>
            <FlexCentredRow className='gap-[2rem]'>
                <Text size='1.4rem' weight='500' lineHeight='2.4rem'>{enabled? 'Enabled' : 'Disabled'}</Text>
                <ToggleSwitch checked={enabled} setChecked={handleToggleEnabled}/>
                </FlexCentredRow>
        </FlexRowSpaceBetween>
        <Text size='1.4rem' weight='400' lineHeight='2.4rem' top='2.4rem' bottom='1rem'align='left'>Payroll approvers are employees designated to review and approve payrolls in your organization before processing.</Text>
   
        {enabled && (
            <>
        
                
                <Formik
                    initialValues={{
                            enable: payrollApproval?.enable ||false,
                            approvalType: payrollApproval?.approvalType || "",
                            approvers: payrollApproval?.approvers ||[]
                          
                    }}
                    enableReinitialize
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        values.approvers = selectedApprovers?.map((staff) => ({
                            userId: staff?._id,
                            type: capitalizeFirstLetter(staff?.userType) ,
                          }));
                          
                        values.approvalType = typeOfApproval;
                        const payload = {
                            enable: enabled, 
                            approvalType: values.approvalType,
                            approvers: [...values.approvers],
                        }
                        const res = await dispatch(updatePayrollApproval(payload));
                        if (res) {
                            setToggleRefetch((prevState) => !prevState)
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            
                                <div ref={dropdownRef} className="mb-[3rem] relative ml-[8rem] z-50">
                                    <Text className="mb-[2rem]" weight="500" align="left" size="1.4rem">
                                        Staff Name or Staff Number
                                    </Text>
                                    <SearchInput
                                        width="45rem"
                                        onKeyDown={()=>setDropdown(true)}
                                        onClose={() => setSearchValue('')}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        placeholder="Search for Staff here"
                                        className="border mt-[1rem] p-2 w-full rounded"
                                    />
                                    {staffList?.length > 0 && dropdown && (
                                        <div className="absolute w-[45rem]  border mt-2 bg-white rounded shadow-lg">
                                            {staffList?.map((staff) => (
                                                <FlexRowSpaceBetween
                                                    key={staff?._id}
                                                    onClick={() => handleAddStaff(staff, setFieldValue)}
                                                    className="hover:bg-gray-200 hover:rounded-lg hover:px-[1rem] m-[1rem]"
                                                >
                                                    <FlexCentredRow className="gap-[1rem]">
                                                        {staff?.profile?.url ? (
                                                            <img
                                                                alt="profile"
                                                                src={staff?.profile?.url}
                                                                className="rounded-full w-[3rem] h-[3rem]"
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                name={`${staff?.firstName} ${staff?.surname}`}
                                                                size="30"
                                                                textSizeRatio={1.75}
                                                                className="rounded-full"
                                                            />
                                                        )}
                                                        <FlexCentredCol className="gap-[0.1rem]">
                                                            <Text
                                                                align="left"
                                                                weight="500"
                                                                size="1.4rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.firstName} {staff?.surname}
                                                            </Text>
                                                            <Text
                                                                align="left"
                                                                color="#6B7280"
                                                                weight="400"
                                                                size="1.2rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.staffEmail}
                                                            </Text>
                                                        </FlexCentredCol>
                                                    </FlexCentredRow>
                                                    <Text align="left" weight="400" size="1.2rem" lineHeight="2.4rem">
                                                        {staff?.staffId}
                                                    </Text>
                                                </FlexRowSpaceBetween>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            
                            
                                <div className="bg-[#F3F4F6] border border-[#9CA3AF] rounded-2xl ml-[8rem]">
                                    <Text
                                        className="border-b border-[#9CA3AF] p-[2.4rem]"
                                        align="left"
                                        weight="600"
                                        size="1.6rem"
                                        lineHeight="2.4rem"
                                    >
                                        Selected persons that can approve payroll
                                    </Text>

                                    <FlexCentredCol className=" flex p-[2.4rem]">
                                        {selectedApprovers?.length === 0 ? (
                                            <Text color="#6B7280" weight="400" size="1.4rem" lineHeight="2.4rem">
                                                Staff added will appear here
                                            </Text>
                                        ) : (
                                            selectedApprovers?.map((staff, _id) => (
                                                <FlexRowSpaceBetween
                                                    key={_id}
                                                    className="p-2 border-b w-full last:border-0"
                                                >
                                                    <FlexCentredRow className="gap-[1rem]">
                                                        {staff?.profilePicture ? (
                                                            <img
                                                                alt="profile"
                                                                src={staff?.profilePicture}
                                                                className="rounded-full w-[5rem] h-[5rem]"
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                name={`${staff?.surname}`}
                                                                size="60"
                                                                textSizeRatio={1.75}
                                                                className="rounded-full"
                                                            />
                                                        )}
                                                        <FlexCentredCol className="gap-[0.4rem]">
                                                            <Text
                                                                align="left"
                                                                weight="500"
                                                                size="1.4rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.firstName} {staff?.surname}
                                                            </Text>
                                                            <Text
                                                                align="left"
                                                                color="#6B7280"
                                                                weight="400"
                                                                size="1.2rem"
                                                                lineHeight="2.4rem"
                                                            >
                                                                {staff?.staffEmail}
                                                            </Text>
                                                        </FlexCentredCol>
                                                    </FlexCentredRow>

                                                    <FlexRowEnd>
                                                        <CloseIcon
                                                            className="cursor-pointer"
                                                            onClick={() => handleRemoveStaff(staff?._id, setFieldValue)}
                                                        />
                                                    </FlexRowEnd>
                                                </FlexRowSpaceBetween>
                                            ))
                                        )}
                                    </FlexCentredCol>
                                </div>
                                <FlexCentredCol className='gap-[2rem] mt-[2rem]'>
                                <RadioInput
                                    name="approvalType"
                                    label="Any approval can approve payroll"
                                    checked={values?.approvalType === 'any'}
                                    onClick={() => {
                                        setFieldValue('approvalType', 'any')
                                        setTypeOfApproval('any')
                                    }}
                                    />
                                <RadioInput
                                    name="approvalType"
                                    label="Only Final approval can approve payroll"
                                    checked={values?.approvalType === 'final'}
                                    onClick={() => {
                                        setFieldValue('approvalType', 'final')
                                        setTypeOfApproval('final')
                                    }}
                                />
                            </FlexCentredCol>

                            <FlexRowEnd className="mt-[2.5rem]">
                                <LoadingButton
                                //  loading={isLoading} 
                                 type="submit">
                                    Update
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
                </>
        )}
            </Container>
        </>
    );
};

export default PayrollApproval;

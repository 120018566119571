import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import { Button } from '../../../../../components/buttons';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { getArchiveStaffById, getManualStaffById, getStaffSalaryInfoById } from '../../../../../redux/bursary/actions';
import { getStaffById } from '../../../../../redux/staff/actions';

import EditSalaryInformation from './edit-salary-info';
import SalaryChangeLog from './tabs/change-log';
import PayrollHistory from './tabs/payroll-history';
import SalaryItems from './tabs/salary-items';

const StaffAccountDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { state } = useLocation();

    const [openModal, setOpenModal] = useState(null);
    const [staffSalaryInfo, setStaffSalaryInfo] = useState(null);
    const [staffDetails, setStaffDetails] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const rawUrl = window?.location?.href;
    const urlObj = new URL(rawUrl);
    const staffType = urlObj.searchParams.get('staff');

    const fetchManualStaffById = async () => {
        const res = await dispatch(getManualStaffById(id));
        setStaffDetails(res);
    };

    const fetchArchiveStaffById = async () => {
        const res = await dispatch(getArchiveStaffById(id, state));
        setStaffDetails(res);
    };

    const fetchPlatformStaffById = async () => {
        const res = await dispatch(getStaffById(id));
        const arrayedStaff = [res?.staff];
        const modifiedStaffData = arrayedStaff?.map((staff) => {
            const staffName = `${staff?.surname || ''} ${staff?.firstName || ''} ${staff?.otherName || ''}`;
            return {
                ...staff,
                staffName: staffName,
                staffPhoneNumber: staff?.phoneNumber,
            };
        });
        setStaffDetails(modifiedStaffData?.[0]);
    };

    useEffect(() => {
        if (staffType === 'manual') {
            fetchManualStaffById();
        } else if (staffType === 'archive') {
            fetchArchiveStaffById();
        } else {
            fetchPlatformStaffById();
        }
        //eslint-disable-next-line
    }, [dispatch, id, staffType]);

    const fetchStaffSalaryInfoById = async () => {
        const res = await dispatch(getStaffSalaryInfoById(id));
        setStaffSalaryInfo(res);
    };

    useEffect(() => {
        if (!id) return;
        fetchStaffSalaryInfoById();
        //eslint-disable-next-line
    }, [id, refetch]);

    const salaryInformation = [
        { key: 'Amount type', value: staffSalaryInfo?.salaryType || '' },
        { key: 'Currency', value: staffSalaryInfo?.currency || '' },
        { key: 'Amount', value: staffSalaryInfo?.salaryAmount },
    ];

    const staffAccountDetails = [
        {
            key: 'Staff Name',
            value: staffDetails?.staffName || '',
            align: 'left',
        },
        {
            key: 'Employment Type',
            value: staffDetails?.designation || '',
            align: 'left',
        },
        {
            key: 'Organization Unit',
            value: '' || '',
            align: 'left',
        },
        {
            key: 'Bank Account Number',
            value: '' || '',
            align: 'right',
        },
        {
            key: 'Bank Account Name',
            value: '' || '',
            align: 'left',
        },
        {
            key: 'Currency',
            value: `` || '',
            align: 'left',
        },
        {
            key: 'Email',
            value: staffDetails?.staffEmail || '',
            align: 'left',
        },
        {
            key: 'Phone Number',
            value: staffDetails?.staffPhoneNumber || '',
            align: 'right',
        },
        {
            key: 'Staff Number',
            value: staffDetails?.staffNumber || '',
            align: 'left',
        },
        {
            key: 'Payment Group',
            value: '' || '',
            align: 'left',
        },
    ];

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Staff Details
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {staffDetails?.staffName || ''}
                </PageTitle>
            </FlexCentredRow>

            <FlexCentredCol>
                <PageTitle align="left">{staffDetails?.staffName || ''}</PageTitle>
            </FlexCentredCol>

            <div className="bg-white p-[2.4rem] rounded-[8px] my-[2.4rem] w-full">
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-4 w-full">
                    {staffAccountDetails?.map((item, index) => (
                        <div key={index} className="">
                            <Text align={item?.align} size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align={item?.align} size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>

            <div className="bg-white p-[2.4rem] rounded-[8px] my-[2.4rem]">
                <FlexRowSpaceBetween className="mb-5">
                    <PageTitle align="left">Salary Information</PageTitle>
                    <Button
                        onClick={() => setOpenModal('edit-salary')}
                        border="0.5px solid #E5E7EB"
                        className="shadow-closeButton"
                    >
                        <EditIcon fill="#1f2937" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
                <div className="grid grid-cols-3 gap-[2.4rem]">
                    {salaryInformation?.map((item, index) => (
                        <div className="">
                            <Text align={index !== 2 ? 'left' : 'right'} size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align={index !== 2 ? 'left' : 'right'} size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
            </div>

            <div className="">
                <CTabs>
                    <CNav variant="tabs" className="">
                        <CNavItem>
                            <CNavLink>Payment History</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink>Salary Items</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink>Salary Change Log</CNavLink>
                        </CNavItem>
                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <PayrollHistory staffId={id} refetch={refetch} />
                        </CTabPane>
                        <CTabPane>
                            <SalaryItems />
                        </CTabPane>
                        <CTabPane>
                            <SalaryChangeLog />
                        </CTabPane>
                    </CTabContent>
                </CTabs>
            </div>
            <EditSalaryInformation
                staffId={id}
                refetch={() => setRefetch(!refetch)}
                show={openModal === 'edit-salary'}
                close={() => setOpenModal(null)}
                salaryInfo={staffSalaryInfo}
            />
        </div>
    );
};

export default StaffAccountDetails;

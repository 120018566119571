import React from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';
import * as Yup from 'yup';

import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import { ReactComponent as PayrollIcon } from '../../../../../assets/icons/payroll.svg';
import { LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import Select from '../../../../../components/inputs/new-select';
import TextInput from '../../../../../components/inputs/text-input';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredCol, FlexCentredRow } from '../../../../../containers/ScreenContainers';
import { editStaffSalaryInfoById } from '../../../../../redux/bursary/actions';

const validationSchema = Yup.object().shape({
    currency: Yup.string()
        .required('Currency is required')
        .matches(/^[A-Z]{3}$/, 'Invalid currency format (e.g., NGN)'),
    salaryAmount: Yup.number()
        .typeError('Amount must be a valid number')
        .positive('Amount must be a positive number')
        .required('Amount is required'),
    salaryType: Yup.string().required('Amount type is required').oneOf(['Gross', 'Net'], 'Invalid amount type'),
});

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const EditSalaryInformation = ({ salaryInfo, show, close, isLoading, staffId, refetch }) => {
    const dispatch = useDispatch();

    return (
        <div>
            <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowEnd className="pb-[1rem]">
                    <Close onClick={close} className="cursor-pointer" />
                </FlexRowEnd>

                <Formik
                    initialValues={{
                        currency: salaryInfo?.currency || '',
                        salaryAmount: salaryInfo?.salaryAmount || '',
                        salaryType: salaryInfo?.salaryType || '',
                    }}
                    enableReinitialize
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(editStaffSalaryInfoById(staffId, values));
                        if (res) {
                            actions.resetForm();
                            refetch();
                            close();
                        }
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue, handleChange, errors, isSubmitting }) => (
                        <Form>
                            <FlexCentredCol className="gap-[3.2rem] mb-[2rem]">
                                <FlexCentredRow className="gap-3">
                                    <PayrollIcon />
                                    <Text as="h3" size="1.9rem" weight="600">
                                        Edit Salary Information
                                    </Text>
                                </FlexCentredRow>
                                <Select
                                    label="Amount Type"
                                    name="salaryType"
                                    objProp="name"
                                    useComponentState={false}
                                    data={[{ name: 'Gross' }, { name: 'Net' }]}
                                    placeholder="Select Amount Type"
                                    onChange={(selected) => {
                                        setFieldValue('salaryType', selected?.[0]?.name);
                                    }}
                                    passedSelectedItems={[{ name: values?.salaryType }]}
                                />
                                <Select
                                    label="Currency"
                                    name="currency"
                                    objProp="name"
                                    useComponentState={false}
                                    data={[{ name: 'NGN' }]}
                                    passedSelectedItems={[{ name: values?.currency }]}
                                    placeholder="Select Currency"
                                    onChange={(selected) => {
                                        setFieldValue('currency', selected?.[0]?.name);
                                    }}
                                />
                                <TextInput
                                    name="salaryAmount"
                                    onChange={handleChange}
                                    label="Amount"
                                    type="text"
                                    placeholder="Input Amount"
                                    errors={errors}
                                    value={values?.salaryAmount}
                                />
                            </FlexCentredCol>

                            <FlexRowEnd className="gap-[1.5rem] mt-12">
                                <CancelButton type="button" onClick={close}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading || isSubmitting}
                                    disabled={isLoading || isSubmitting}
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </StyledModal>
        </div>
    );
};

export default EditSalaryInformation;

import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch} from 'react-redux';
import tw from 'twin.macro';

import {ReactComponent as Close} from '../../../../assets/icons/circular-close-red.svg'
import {ReactComponent as Delete} from '../../../../assets/icons/delete.svg'
import {ReactComponent as Plus} from '../../../../assets/icons/plus-blue.svg'
import {  LoadingButton } from '../../../../components/buttons';
import ToggleSwitch from '../../../../components/inputs/switch';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { updatePayrollSalaryBreakdown } from '../../../../redux/bursary/actions';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
`;

const SalaryBreakdown = ({salaryBreakdown,setToggleRefetch}) => {
    const dispatch = useDispatch();
 const [salaryItems, setSalaryItems] = useState([]);
 const [enabled, setEnabled] = useState(salaryBreakdown?.enable || false);

 const handleAddItem = () => {
   setSalaryItems([
     ...salaryItems,
     { description: '', percentage:''  },
   ]);
 };

 
 useEffect(() => {
    if (salaryBreakdown) {
      setSalaryItems(
        salaryBreakdown.items?.map((item) => ({
          description: item.description,
          percentage: item.percentage,
        })) || []
      );
      setEnabled(salaryBreakdown.enable || false);
    }
  }, [salaryBreakdown]);

 const handleUpdate = async () => {
    const items = salaryItems?.map((item) => ({
            description: item.description,
            percentage: item.percentage,
    }));
    const payload = {

        enable: enabled,
        items:[...items]
    }

    const res = await dispatch(updatePayrollSalaryBreakdown(payload));
    if (res) {
        
        setToggleRefetch((prevState) => !prevState);
    
    }

  };

 const handleEditItem = (index, newDescription, newPercentage) => {
   const updatedItems = [...salaryItems];
   updatedItems[index] = {
     description: newDescription,
     percentage: newPercentage,
   };
   setSalaryItems(updatedItems);
 };

 const handleDeleteItem = (index) => {
   const updatedItems = [...salaryItems];
   updatedItems.splice(index, 1);
   setSalaryItems(updatedItems);
 };
 const totalPerc = useMemo(() => {
  return salaryItems.reduce((total, item) => total + (parseFloat(item.percentage) || 0), 0);
}, [salaryItems]);


 const handleToggleEnabled = () => {
   setEnabled(!enabled);
 };

 return (
   <Container className="">
     <FlexRowSpaceBetween>
            <Text size='1.6rem' weight='500' lineHeight='2.8rem'>Salary Breakdown</Text>
            <FlexCentredRow className='gap-[2rem]'>
                <Text size='1.4rem' weight='500' lineHeight='2.4rem'>{enabled? 'Enabled' : 'Disabled'}</Text>
                <ToggleSwitch checked={enabled} setChecked={handleToggleEnabled}/>
                </FlexCentredRow>
    </FlexRowSpaceBetween>
    {enabled && (
        <>
        <Text size='1.4rem' weight='400' lineHeight='2.4rem' top='2.4rem' bottom='2rem' align='left'>Break down employees' base salaries into specific components such as Basic Pay, Housing Allowance, and Transport Allowance. You can also add custom components to reflect your institution's unique benefits, such as Medical Allowance, Research Grants, or Performance Bonuses.</Text>
   
    {salaryItems.length > 0 && (
      <table className='rounded-2xl shadow border w-full'>
        <thead>
          <tr>
            <th className='bg-[#F9FAFB] text-[#6B7280] leading-6 font-medium p-[2rem] text-[1.4rem]'>Description</th>
            <th className='bg-[#F9FAFB] text-[#6B7280] leading-6 font-medium p-[2rem] text-[1.4rem] border-l'>Percentage (%)</th>      
          </tr>
        </thead>
        <tbody>
          {salaryItems.map((item, index) => (
            <tr className='border-b' key={index}>
              <td >
                <FlexRowEnd>
              
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) =>
                    handleEditItem(index, e.target.value, item.percentage)
                  }
                  
                 className="bg-[#F9FAFB] p-[2rem] outline-none placeholder:text-[#747e91] placeholder:text-[1.4rem] leading-6 font-normal text-[1.4rem] text-[#1F2937] w-[3/4]"
                />
                </FlexRowEnd>
              </td>
              <td>
                <FlexRowSpaceBetween className="px-[2rem]">
                    
                <input
                  type="text"
                  value={item.percentage}
                  onChange={(e) =>
                    handleEditItem(
                      index,
                      item.description,
                      parseFloat(e.target.value) || 0
                    )
                  }
                  className="p-2 pl-3 focus:ring-[#6366F1] focus:ring-1 outline-none placeholder:text-[#747e91] placeholder:text-[1.4rem] leading-6 font-normal text-[1.4rem] text-[#1F2937]"
                />
                <FlexCentredRow className='gap-[2rem]'>
                <Delete onClick={() => handleDeleteItem(index)} />
                </FlexCentredRow>
                
                    
                </FlexRowSpaceBetween>
              </td>
             
            </tr>
          ))}
          <tr>
            <td className="text-[#1F2937] leading-6 font-medium p-[2rem] text-[1.4rem]">Total</td>
            <td className="text-[#1F2937] leading-6 font-medium p-[2rem] text-[1.4rem]">{totalPerc}%</td>
            <td />
          </tr>
        </tbody>
      </table>
    )}

     {salaryItems.length > 0 && totalPerc > 0 && totalPerc!== 100 && (
        <div className="bg-[#FEF2F2] rounded-lg p-[2rem] my-[2rem]">
            <FlexCentredRow className="gap-[2rem]">
                <Close />
                <Text>Total percentage must equal 100%.</Text>
            </FlexCentredRow>
          
        </div>
      )}
     <FlexRowSpaceBetween className="mt-[4rem] cursor-pointer "> 
        <FlexCentredRow className="gap-[2rem] " onClick={handleAddItem}>
            <Plus />
            <Text size="1.4rem" color="#6366F1">Add Salary Item</Text>
        </FlexCentredRow>
       
        <LoadingButton 
            bgColor="#6366F1"
            color="#fff"
            disabled={totalPerc !== 100}
            onClick={handleUpdate}>
                Update
        </LoadingButton>
     </FlexRowSpaceBetween>
     
     </>
)}
   </Container>
  );
};

export default SalaryBreakdown;
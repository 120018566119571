import React from 'react';

import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as PaperIcon } from '../../../../assets/icons/people.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';
import { editOrganizationStaff } from '../../../../redux/campus-management/actions';
import validationSchema from '../invite-org-staff/ValidationSchema';
import { getOrgRoleTypeName, ORGANIZATION_PERMISSIONS, ORGANIZATION_ROLES } from '../utils';

const EditOrganizationStaff = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { state: staffData } = useLocation();
    const { isLoading } = useSelector((store) => store.campus);

    return (
        <>
            <div className="mb-0">
                <GoBack title="Access Manager" subTitle="Edit Staff" />
            </div>
            <div className="flex gap-[5rem] pb-6">
                <div className="bg-white p-[3.5rem] rounded-2xl w-[1000px] overflow-y-auto mt-[3.2rem] max-h-[65rem]">
                    <Formik
                        initialValues={{
                            firstName: staffData?.firstName || '',
                            surname: staffData?.surname || '',
                            otherName: staffData?.otherName || '',
                            staffEmail: staffData?.staffEmail || '',
                            yearOfEmployment: staffData?.yearOfEmployment || '',
                            phoneNumber: staffData?.phoneNumber || '',
                            staffNumber: staffData?.staffNumber || '',
                            organizationRoleType: staffData?.organizationRoleType || '',
                        }}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                            const date = format(new Date(values.yearOfEmployment), 'yyyy-MM-dd');

                            const payload = { ...values, yearOfEmployment: date };
                            const res = await dispatch(editOrganizationStaff(payload, id));
                            if (res) {
                                navigate(-1);
                            }
                        }}
                    >
                        {({ errors, handleChange, values, touched, setFieldValue }) => (
                            <Form>
                                <>
                                    <FlexCentredRow className="gap-2 mb-8">
                                        <PaperIcon />
                                        <Text weight="600" size="1.6rem">
                                            Edit Staff
                                        </Text>
                                    </FlexCentredRow>
                                    <div className="grid md:grid-cols-2 grid-rows-4 gap-x-6 gap-y-5">
                                        <TextInput
                                            name="surname"
                                            type="text"
                                            label="Surname"
                                            placeholder="Surname"
                                            errorMessage={errors.surname}
                                            onChange={handleChange}
                                            value={values.surname}
                                        />
                                        <TextInput
                                            name="firstName"
                                            type="text"
                                            label="First Name"
                                            placeholder="First Name"
                                            errorMessage={errors.firstName}
                                            onChange={handleChange}
                                            value={values.firstName}
                                        />
                                        <TextInput
                                            name="otherName"
                                            label="Other Names (Optional)"
                                            type="text"
                                            placeholder="Other Names"
                                            errorMessage={errors.otherName}
                                            onChange={handleChange}
                                            value={values.otherName}
                                        />
                                        <TextInput
                                            name="phoneNumber"
                                            type="text"
                                            label="Phone Number"
                                            placeholder="Phone Number"
                                            errorMessage={errors.phoneNumber}
                                            onChange={handleChange}
                                            value={values.phoneNumber}
                                        />
                                        <TextInput
                                            name="staffNumber"
                                            type="text"
                                            label="Staff Number (Optional)"
                                            placeholder="Staff Number"
                                            errorMessage={errors.staffNumber}
                                            onChange={handleChange}
                                            value={values.staffNumber}
                                        />
                                        <TextInput
                                            name="staffEmail"
                                            type="text"
                                            label="Email Address"
                                            placeholder="Email Address"
                                            errorMessage={errors.staffEmail}
                                            onChange={handleChange}
                                            value={values.staffEmail}
                                        />
                                        <DatePicker
                                            label="Employment Date"
                                            touched={touched}
                                            name="yearOfEmployment"
                                            maxDate={new Date()}
                                            initialValue={
                                                values.yearOfEmployment ? new Date(values.yearOfEmployment) : null
                                            }
                                        />
                                        <SelectInput
                                            name="organizationRoleType"
                                            containerClass="text-[#1F2937]"
                                            objProp="name"
                                            label="Organization Role"
                                            placeholder="Select role"
                                            data={ORGANIZATION_ROLES || []}
                                            onChange={(selected) => {
                                                setFieldValue('organizationRoleType', selected[0].value);
                                            }}
                                            passedSelectedItems={
                                                values.organizationRoleType
                                                    ? [
                                                          {
                                                              name: getOrgRoleTypeName(values.organizationRoleType),
                                                          },
                                                      ]
                                                    : null
                                            }
                                            error={errors.organizationRoleType}
                                        />
                                    </div>
                                    <FlexRowEnd className="gap-3 mt-24">
                                        <CancelButton type="button" onClick={() => navigate(-1)}>
                                            Cancel
                                        </CancelButton>
                                        <LoadingButton isLoading={isLoading} type="submit">
                                            Update Staff
                                        </LoadingButton>
                                    </FlexRowEnd>
                                </>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className="w-[477px] bg-white p-[3.2rem] mt-[3.2rem]">
                    <Text weight="600" size="1.6rem" align="left">
                        Organisational Permissions
                    </Text>
                    <div className="mt-[3rem] flex flex-col gap-[2.4rem]">
                        {ORGANIZATION_PERMISSIONS?.map((item, i) => (
                            <div className="" key={i}>
                                <Text weight="600" size="1.6rem" align="left">
                                    {item.title}
                                </Text>{' '}
                                <Text weight="400" size="1.6rem" align="left">
                                    {' '}
                                    - {item?.description}
                                </Text>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditOrganizationStaff;

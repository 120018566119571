import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI, commsAPI } from '../../config';
import {
    GET_ALL_BULLETINS,
    GET_ALL_INTERNAL_MEMOS,
    GET_ALL_RECEIVED,
    GET_RECEIVED_NOTICE,
} from '../communications/slice';

import {
    LOADING,
    STOP_LOADING,
    GET_STAFF_DIRECTORY,
    GET_STAFF_FILES,
    GET_RECEIVED_FILES,
    GET_SINGLE_FILE,
    GET_PROFILE_DETAILS,
    GET_STAFF,
    GET_STAFF_ACAD,
    GET_STAFF_BIO,
    GET_ALL_LETTER,
    GET_SENT_LETTERS,
    GET_LETTER,
    GET_STAFF_HANDBOOK_BY_ID,
    GET_SESSIONS,
    GET_STAFF_UPLOADS,
    GET_STAFF_PUBLICATION,
    GET_UPLOADED_FILES,
    GET_CREATED_FOLDERS,
    GET_SEARCH_STAFF,
    GET_SEARCH_FOLDER,
    GET_FOLDER_DETAILS,
    GET_FILE_DETAILS,
    GET_PAYROLL_DETAILS,
} from './slice';

export const changePassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/preference/change-password`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffDirectory = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/directory`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_STAFF_DIRECTORY(data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffHandbook = (session) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/get-Handbook/${session}`);

        const { data } = response?.data || {};
        return data || [];
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffArenaSession = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/all_session`);
        dispatch(GET_SESSIONS(response.data.data.sessions));
        return true;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffHandbookById = (id, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/get-Handbook_by_id/${session}/${id}`);
        dispatch(GET_STAFF_HANDBOOK_BY_ID(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffArenaDetails = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/get-staff-detail`);
        if (response.status === 200) {
            const { data } = response?.data || {};

            const { academy, experience, bio, upload, publication, payroll } = data || {};

            const transformeBio = {
                ...bio,
                personalInfo: {
                    ...bio?.personalInfo,
                    ...bio?.nextOfKinInfo,
                    nextOfKinName: bio?.nextOfKinInfo?.nextOfKin || bio?.personalInfo?.nextOfKinName,
                    relationshipWithNextOfKin:
                        bio?.nextOfKinInfo?.nextOfKinRelationship || bio?.personalInfo?.relationshipWithNextOfKin,
                },
            };
            dispatch(GET_STAFF(data.staff || data.stff));
            dispatch(GET_STAFF_ACAD(academy || experience));
            dispatch(GET_STAFF_BIO(transformeBio));
            dispatch(GET_STAFF_UPLOADS(upload));
            dispatch(GET_STAFF_PUBLICATION(publication?.uploadPublication || []));
            dispatch(GET_PAYROLL_DETAILS(payroll));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getProfileDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/profile/${id}`);

        if (response.status === 200) {
            dispatch(GET_PROFILE_DETAILS(response?.data?.data));
            return response.data.data;
        }
    } catch (err) {
        toast.error(err.data.message);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const postStaffBiodata = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/staff-biodata`, payload, headers);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'You have Successfully created your record ';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const postStaffAcademic = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/staff-academic`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'You have Successfully updated your academic';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createTeachingStaffWOrkExp = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/teaching/experience`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Work experience information successfully updated!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPayrollDetails = (id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/payrolls/${id}`);

        if (response.status === 200) {
            dispatch(GET_PAYROLL_DETAILS(response?.data?.data));
            return response.data.data;
        }
    } catch (err) {
        const errorMessage = err?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createPayroll = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/payrolls`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Payroll successfully saved!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editPayroll = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/payrolls/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Payroll successfully saved!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createStaffPublication = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };

        const response = await axiosInstance.patch(`${currentAPI}staff-arena/teaching/publication`, payload, headers);
        if (response.status === 200 || response.status === 201) {
            const successMessage =
                response?.data?.data.message || 'Staff publication information successfully updated!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffUploads = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/teaching/certificates`, payload, headers);
        if (response.status === 200 || response.status === 201) {
            const successMessage = response?.data?.data.message || 'Staff uploads information successfully updated!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllReceivedComms = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/received`);
        if (response.status === 200) {
            dispatch(GET_ALL_RECEIVED(response.data.payload));
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getInboxLetterComms = (param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/letter?${param}=${param}`);
        if (response.status === 200) {
            dispatch(GET_ALL_LETTER(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getMemoById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/office/memo/${id}`);
        if (response.status === 200) {
            // dispatch(GET_ALL_LETTER(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentLetterComms = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/letter`);
        if (response.status === 200) {
            dispatch(GET_ALL_LETTER(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllNoticeComms = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/notice`);
        if (response.status === 200) {
            dispatch(GET_RECEIVED_NOTICE(response.data.payload));
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllInternalMemoComms = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/internal-memo`);
        if (response.status === 200) {
            dispatch(GET_ALL_INTERNAL_MEMOS(response.data.payload));
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBulletinComms = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/bulletin`);
        if (response.status === 200) {
            dispatch(GET_ALL_BULLETINS(response.data.payload));
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendOfficeLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/letter`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendOfficeDraftLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/letter/draft`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftMemo = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/memo/draft`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendStaffLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/letter/personal`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSentLetters = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/sent-letters`);
        if (response.status === 200) {
            dispatch(GET_SENT_LETTERS(response.data.payload));
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSent = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/all-sent`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getLetterById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/letter/${id}`);
        if (response.status === 200) {
            dispatch(GET_LETTER(response.data.payload));
            return response.data.payload;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffDraft = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/personal/draft`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getOfficeDraft = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/office/draft`);
        if (response.status === 200) {
            return response.data.payload;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftOfficeLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/letter/office/draft`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendDraftStaffLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/letter/personal/draft`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllMemo = (param) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/office/memo?${param}=${param}`);
        if (response.status === 200) {
            return response.data.payload;
            // return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSentMemo = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/office/memo`);
        if (response.status === 200) {
            return response.data.payload;
            // return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllInbox = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}staff-arena/office/received`);
        if (response.status === 200) {
            return response.data.payload;
            // return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

// endpoints for documents

export const createFolder = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/folders`, payload);
        const successMessage = response?.data?.message || 'Folder created Successfully';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const renameFile = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/rename/file`, payload);
        const successMessage = response?.data?.message || 'File renamed Successfully';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const renameFolder = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/rename`, payload);
        const successMessage = response?.data?.message || 'Folder renamed Successfully';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const moveFile = (payload, id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/file/${id}/move`, payload);
        const successMessage = response?.data?.message || 'File moved Successfully';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const moveFolder = (payload, id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/${id}/move`, payload);
        const successMessage = response?.data?.message || 'Folder moved Successfully';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const patchSetttings = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/me/privacy/settings`, payload);
        const successMessage = response?.data?.message || 'File sharing set Successfully';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const uploadFile = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/upload/file`, payload, headers);
        const successMessage = response?.data?.message || 'Upload Successful';
        if (response.status === 201) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const shareFile = (id, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/sharing/${id}/file`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'File shared with users successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const shareFolder = (id, payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}staff-arena/folders/sharing/${id}/folder`, payload);
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Folder shared with users successfully';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNestedFolder = (id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/folders/${id}/inside`);
        if (response.status === 200) {
            dispatch(GET_FOLDER_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNestedFile = (id) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/folders/${id}/file`);
        if (response.status === 200) {
            dispatch(GET_FILE_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSearchStaff = (search) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/search-staff?search=${search}`);
        if (response.status === 200) {
            dispatch(GET_SEARCH_STAFF(response?.data));

            return response.data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSearchFolder = (search) => async (dispatch) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/folders/search?search=${search}`);
        if (response.status === 200) {
            dispatch(GET_SEARCH_FOLDER(response?.data?.data));

            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getUploadedFile = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/folders/file/upload`);
        if (response.status === 200) {
            dispatch(GET_UPLOADED_FILES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteFile = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}staff-arena/folders/delete/file`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'File Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteFolder = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}staff-arena/folders/delete/folder`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Folder Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCreatedFolders = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/folders`);
        if (response.status === 200) {
            dispatch(GET_CREATED_FOLDERS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getReceivedStaffFiles = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/received-shared-file`);
        if (response.status === 200) {
            dispatch(GET_RECEIVED_FILES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getUploadedStaffFiles = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/uploads-file`);
        if (response.status === 200) {
            dispatch(GET_STAFF_FILES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteUploadedStaffFiles = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}staff-arena/delete-file/${id}`);
        if (response.status === 200) {
            dispatch(getUploadedStaffFiles());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffFileById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff-arena/get-single-upload/${id}`);
        if (response.status === 200) {
            dispatch(GET_SINGLE_FILE(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const uploadStaffFiles = (payload, headers) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff-arena/staff-upload-file`, payload, headers);
        const successMessage = response?.data?.message || 'Upload Successful';
        if (response.status === 201) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendOfficeMemo = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}staff-arena/memo`, payload);
        if (response.status === 201) {
            return response;
        }
    } catch (error) {
        return;
    } finally {
        dispatch(STOP_LOADING());
    }
};

import React from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';
import * as Yup from 'yup';

import { ReactComponent as Close } from '../../../../../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../../../../../components/buttons';
import { CancelButton } from '../../../../../../../../components/forms/sharedStyles';
import TextInput from '../../../../../../../../components/inputs/text-input';
import { Text } from '../../../../../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredCol, FlexCentredRow } from '../../../../../../../../containers/ScreenContainers';
import { editSalaryDeduction } from '../../../../../../../../redux/bursary/actions';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const validationSchema = Yup.object().shape({
    bonusName: Yup.string().required('Name is required'),
    amount: Yup.number()
        .typeError('Amount must be a valid number')
        .positive('Amount must be a positive number')
        .required('Amount is required'),
    reason: Yup.string().required('Description is required'),
});

const EditDeduction = ({ show, close, isLoading, editIcon, deductionData, refetch }) => {
    const dispatch = useDispatch();

    return (
        <div>
            <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowEnd className="pb-[1rem]">
                    <Close onClick={close} className="cursor-pointer" />
                </FlexRowEnd>

                <Formik
                    initialValues={
                        deductionData || {
                            amount: '',
                            bonusName: '',
                            reason: '',
                        }
                    }
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(editSalaryDeduction(deductionData?._id, values));
                        if (res) {
                            actions?.resetForm();
                            refetch();
                            close();
                        }
                    }}
                >
                    {({ values, handleChange, errors, isSubmitting }) => (
                        <Form>
                            <FlexCentredCol className="gap-[3.2rem] mb-[2rem]">
                                <FlexCentredRow className="gap-3">
                                    {editIcon}
                                    <Text as="h3" size="1.9rem" weight="600">
                                        Edit Deduction
                                    </Text>
                                </FlexCentredRow>
                                <TextInput
                                    name="bonusName"
                                    onChange={handleChange}
                                    label="Name"
                                    type="text"
                                    placeholder="Input Name"
                                    errors={errors}
                                    value={values?.bonusName}
                                    disabled
                                />
                                <TextInput
                                    name="reason"
                                    onChange={handleChange}
                                    label="Purpose/Description"
                                    type="text"
                                    placeholder="Input Description"
                                    errors={errors}
                                    value={values?.reason}
                                />
                                <TextInput
                                    name="amount"
                                    onChange={handleChange}
                                    label="Amount"
                                    type="text"
                                    placeholder="Input Amount"
                                    errors={errors}
                                    value={values?.amount}
                                />
                            </FlexCentredCol>

                            <FlexRowEnd className="gap-[1.5rem] mt-12">
                                <CancelButton type="button" onClick={close}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading || isSubmitting}
                                    disabled={isLoading || isSubmitting}
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </StyledModal>
        </div>
    );
};

export default EditDeduction;

import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';

import { ExportSheetDialogue } from '../../../../../components';
import { FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../../context/tableContext';
import useDebounce from '../../../../../hooks/useDebounce';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getPaidFull } from '../../../../../redux/bursary/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';

const PaidStudents = ({ paidStudents, loading, currentFee, setPaidStudents, id }) => {
    const dispatch = useDispatch();
    const { paidStudents: responseData } = useSelector((store) => store.bursary);
    const { totalPages, totalNoOfStudents } = responseData || {};
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [currentPage, setCurrentPage] = useState('1');

    const fields = [
        {
            header: 'Names',
            accessorKey: 'studentName',
            cell: ({ row }) => (
                <p>
                    {row.original.firstName} {row.original.lastName}
                </p>
            ),
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'faculty',
            cell: ({ row }) => <p>{row.original?.college || row.original?.faculty || row.original?.school}</p>,
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'program',
            cell: ({ row }) => <p>{row.original?.program || row.original?.department}</p>,
        },
        {
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            header: 'Amount Paid',
            accessorKey: 'totalAmountPaid',
        },
        {
            cell: ({ row }) => (
                <p>
                    {row?.original?.payments?.[0]?.paymentDate
                        ? `${new Date(row?.original?.payments?.[0]?.paymentDate).toLocaleDateString()}, ${new Date(
                              row?.original?.payments?.[0]?.paymentDate,
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
            header: 'Paid On',
            accessorKey: 'createdAt',
        },
    ];

    useEffect(() => {
        if (id) {
            const queries = {
                search: debouncedSearchValue,
                page: currentPage,
                limit: numOfPages,
            };
            const getPaidStudents = async () => {
                const res = await dispatch(getPaidFull(id, queries));
                setPaidStudents(res);
            };
            getPaidStudents();
        }
    }, [id, debouncedSearchValue, currentPage, numOfPages, dispatch, setPaidStudents]);

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={paidStudents || []}
                fields={fields}
                loading={loading}
                isPaginated={true}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={totalNoOfStudents}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
            />

            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
        </>
    );
};

export default PaidStudents;

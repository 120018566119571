import AddPaymentGroup from '../../components/forms/add-payment-group';
import EditPaymentGroup from '../../components/forms/edit-payment-group';
import { BURSARY } from '../../utils/unitNames';
import {
    AcademicCalendar,
    // AddAdministrator,
    AddFee,
    Administrator,
    Bursary,
    CreateRole,
    EditAdministrator,
    Received,
    Roles,
    BursaryStudentDetails,
    Staff,
    StaffDetail,
    EditRole,
    BursarySettings,
    DistributionList,
    AddDistributionList,
    UpdateDistributionList,
    EditFee,
    Billing,
    AddSeat,
    InvoiceHistory,
    BillingPaymentHistory,
    PaymentMethod,
    EditPaymentMethod,
    EditBillingInfo,
    RemoveSeat,
    BillingVerification,
    PaymentGateway,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    DebtorsList,
    AddDebtor,
    DebtorDetails,
    AgentManagement,
    AddAgent,
    EditAgent,
    AttachFee,
    ViewAgent,
    EditDebtor,
    PaymentGroupStaffDetails,
    AddStaffAccount,
    StaffAccountDetails,
    EditStaffAccount,
    AddPaymentGateway,
    EditPaymentGateway,
    GatewayDetails,
    DetachFee,
} from '../../views';
import DiscountList from '../../views/bursary/discounts';
import CreateDiscount from '../../views/bursary/discounts/create-discount';
import DiscountDetails from '../../views/bursary/discounts/discount-details';
import EditDiscount from '../../views/bursary/discounts/edit-discount';
import PaymentGroup from '../../views/bursary/payment-group';
import AddStaffToGroup from '../../views/bursary/payment-group/add-staff-to-group';
import AddStaffToSubGroup from '../../views/bursary/payment-group/add-staff-to-subgroup';
import GroupDetails from '../../views/bursary/payment-group/group-details';
import SubGroupDetails from '../../views/bursary/payment-group/group-with-steps';
import PaymentSubGroup from '../../views/bursary/payment-group/sub-group';
import PayrollSettings from '../../views/bursary/payroll-settings';
import BursaryStudents from '../../views/bursary/student';
import StudentReceipt from '../../views/bursary/student/student-receipt';
import BursaryStudentById from '../../views/bursary/student/studentdetails';

export const bursaryRoutes = [
    {
        path: '/bursary/access',
        element: (
            <Access
                staffs={<Staff unitName={BURSARY} />}
                administrators={<Administrator unitName={BURSARY} />}
                roles={<Roles unitName={BURSARY} />}
            />
        ),
    },
    {
        path: '/bursary/access/create-role',
        element: <CreateRole unitName={BURSARY} />,
    },
    {
        path: '/bursary/access/edit-role',
        element: <EditRole unitName={BURSARY} />,
    },
    {
        path: '/bursary/access/add-administrator',
        // element: <AddAdministrator unitName={BURSARY} />,
        element: <AssignRole unitName={BURSARY} />,
    },
    {
        path: '/bursary/access/edit-administrator',
        element: <EditAdministrator unitName={BURSARY} />,
    },

    {
        path: '/bursary/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/bursary/communications/received',
        element: <Received unitName={BURSARY} />,
    },
    {
        path: '/bursary/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/bursary/fees',
        element: <Bursary unitName={BURSARY} />,
    },
    {
        path: '/bursary/fees/add-fee',
        element: <AddFee />,
    },
    {
        path: '/bursary/fees/fee-details/:id',
        element: <BursaryStudentDetails />,
    },
    {
        path: '/bursary/debtors-list',
        element: <DebtorsList />,
    },
    {
        path: '/bursary/debtors-list/:id',
        element: <DebtorDetails />,
    },
    {
        path: '/bursary/debtors-list/add',
        element: <AddDebtor />,
    },
    {
        path: '/bursary/debtors-list/:id/edit',
        element: <EditDebtor />,
    },
    {
        path: '/bursary/agent-list',
        element: <AgentManagement />,
    },
    {
        path: '/bursary/agent-list/add',
        element: <AddAgent />,
    },
    {
        path: '/bursary/agent-list/edit-agent',
        element: <EditAgent />,
    },
    {
        path: '/bursary/agent-list/attach-fee',
        element: <AttachFee />,
    },
    {
        path: '/bursary/agent-list/detach-fee',
        element: <DetachFee />,
    },
    {
        path: '/bursary/agent-list/:id',
        element: <ViewAgent />,
    },
    {
        path: '/bursary/discounts',
        element: <DiscountList />,
    },
    {
        path: '/bursary/discounts/create',
        element: <CreateDiscount />,
    },
    {
        path: '/bursary/discounts/edit',
        element: <EditDiscount />,
    },
    {
        path: '/bursary/discounts/:id',
        element: <DiscountDetails />,
    },
    {
        path: '/bursary/settings',
        element: <BursarySettings />,
    },
    {
        path: 'bursary/settings/payment-gateway',
        element: <PaymentGateway />,
    },
    {
        path: '/bursary/settings/payment-gateway/add-gateway',
        element: <AddPaymentGateway />,
    },
    {
        path: '/bursary/settings/payment-gateway/edit-gateway',
        element: <EditPaymentGateway />,
    },
    {
        path: '/bursary/settings/payment-gateway/:id',
        element: <GatewayDetails />,
    },
    {
        path: '/bursary/communications/distribution-list',
        element: <DistributionList unitName={BURSARY} title="Communications" />,
    },
    {
        path: '/bursary/communications/distribution-list/add',
        element: <AddDistributionList unitName={BURSARY} title="Communications" />,
    },
    {
        path: '/bursary/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={BURSARY} title="Communications" />,
    },
    {
        path: '/bursary/fees/edit-fee/:id',
        element: <EditFee />,
    },
    {
        path: 'bursary/billing',
        element: <Billing unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/add-seat',
        element: <AddSeat unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/invoice-history',
        element: <InvoiceHistory unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/payment-history',
        element: <BillingPaymentHistory unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/payment-method',
        element: <PaymentMethod unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/payment-method/update',
        element: <EditPaymentMethod unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/invoice-history/edit-billing',
        element: <EditBillingInfo unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/remove-seat',
        element: <RemoveSeat unitName={BURSARY} />,
    },
    {
        path: 'bursary/billing/verification/payment',
        element: <BillingVerification unitName={BURSARY} />,
    },
    {
        path: '/bursary/students',
        element: <BursaryStudents unitName={BURSARY} />,
    },
    {
        path: '/bursary/students/payment-history/:id',
        element: <BursaryStudentById />,
    },
    {
        path: '/bursary/students/payment-history/receipt/:id',
        element: <StudentReceipt />,
    },
    {
        path: '/bursary/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/bursary/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/bursary/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/bursary/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/bursary/payroll/payment-group',
        element: <PaymentGroup unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/:id/group-only',
        element: <GroupDetails unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/:id/group-subgroups',
        element: <PaymentSubGroup unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/:id/group-subgroups/:id',
        element: <SubGroupDetails unitName={BURSARY} />,
    },

    {
        path: '/bursary/payroll/payment-group/add-group',
        element: <AddPaymentGroup unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/edit-group/:id',
        element: <EditPaymentGroup unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/:id/group-only/add-staff',
        element: <AddStaffToGroup unitName={BURSARY} />,
    },
    {
        path: '/bursary/payroll/payment-group/:id/group-subgroups/:id/add-staff',
        element: <AddStaffToSubGroup unitName={BURSARY} />,
    },
    {
        path: 'bursary/payroll/staff-details',
        element: <PaymentGroupStaffDetails />,
    },
    {
        path: 'bursary/payroll/staff-details/add-account',
        element: <AddStaffAccount />,
    },
    {
        path: 'bursary/payroll/staff-details/:id',
        element: <StaffAccountDetails />,
    },
    {
        path: 'bursary/payroll/staff-details/edit-account/:id',
        element: <EditStaffAccount />,
    },
    {
        path: '/bursary/payroll/settings',
        element: <PayrollSettings unitName={BURSARY} />,
    },
];

import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { getStaffSalaryChangeLog } from '../../../../../../redux/bursary/actions';

const fields = [
    {
        header: 'Salary Amount',
        accessorKey: 'salaryAmount',
        cell: ({ row }) => <p>{row?.original?.newValue?.salaryAmount}</p>,
    },
    {
        header: 'Last Updated At',
        accessorKey: 'updatedAt',
        cell: ({ row }) => (
            <p>
                {' '}
                {row?.original?.newValue?.updatedAt
                    ? `${new Date(row?.original?.newValue?.updatedAt).toLocaleDateString()}, ${new Date(
                          row?.original?.newValue?.updatedAt,
                      ).toLocaleTimeString()}`
                    : ''}
            </p>
        ),
    },
    {
        header: 'Amount Type',
        accessorKey: 'salaryType',
        cell: ({ row }) => <p>{row?.original?.newValue?.salaryType}</p>,
    },
    {
        header: 'Currency',
        accessorKey: 'currency',
        cell: ({ row }) => <p>{row?.original?.newValue?.currency}</p>,
    },
];

const SalaryChangeLog = ({ searchValue, setSearchValue, isLoading, refetch }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [salaryHistory, setSalaryHistory] = useState([]);

    const fetchSalaryHistory = async () => {
        const res = await dispatch(getStaffSalaryChangeLog(id));
        setSalaryHistory(res);
    };

    useEffect(() => {
        fetchSalaryHistory();
        //eslint-disable-next-line
    }, [refetch]);

    return (
        <div className="mt-3">
            <PageLayout
                noMargin
                fields={fields}
                data={salaryHistory || []}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                loading={isLoading}
            />
        </div>
    );
};

export default SalaryChangeLog;

import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-ash.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/ChevronRight.svg';
import { GoBack } from '../../../../components/go-back';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../utils';
import currencyFormatter from '../../../../utils/formatCurrency';

import Installment from './tabs/Installment';
import NotPaidStudents from './tabs/NotPaidStudents';
import PaidStudents from './tabs/PaidStudents';

const FeeDetailsContainer = tw.div`
    bg-white
    p-[2.4rem]
    rounded-[8px]
    my-[2.4rem]
`;

const BursaryStudentDetails = () => {
    const { id } = useParams();
    const { state } = useLocation();

    const { currentFee } = state || {};

    const { isLoading } = useSelector((store) => store.bursary);

    const [paidStudents, setPaidStudents] = useState([]);
    const [unpaidStudents, setUnpaidStudents] = useState([]);
    const [installmentStudents, setInstallmentStudents] = useState([]);
    const [showBreakdown, setShowBreakdown] = useState(false);

    const generalFeeDetails = [
        {
            key: 'Type of Fee',
            value: currentFee?.feeType?.replace('-', ' ') || '-',
        },
        {
            key: 'Span',
            value: currentFee?.span || '-',
        },
        {
            key: 'Payment Gateway',
            value: capitalizeFirstLetter(currentFee?.paymentConfig) || '-',
        },
        {
            key: 'Target',
            value: currentFee?.target || '-',
        },
        {
            key: 'Session',
            value: currentFee?.session || '-',
        },
        {
            key: 'Amount Payable',
            value: currencyFormatter.format(currentFee?.amountPayable || 0),
        },
        {
            key: 'Deadline',
            value: currentFee?.deadline?.slice(0, 10) || '-',
        },
        {
            key: 'Payment Schedule',
            value: currentFee?.paymentSchedule?.replace('-', ' ') || '-',
        },
    ];

    return (
        <>
            <GoBack title="Bursary" subtitle={currentFee?.feeName} />

            <FeeDetailsContainer>
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-4 gap-[2.4rem]">
                    {generalFeeDetails?.map((item) => (
                        <div className="">
                            <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
                <div className="">
                    {currentFee?.feeBreakdown?.length > 0 && (
                        <FlexCentredRow
                            className="pt-[3.2rem] gap-[1rem]"
                            onClick={() => setShowBreakdown(!showBreakdown)}
                        >
                            <Text size="1.6rem" weight="600" align="left">
                                Fee Breakdown
                            </Text>

                            <div className="">{showBreakdown ? <ArrowDown height={20} width={20} /> : <ArrowUp />}</div>
                        </FlexCentredRow>
                    )}
                    {showBreakdown && (
                        <div className="grid grid-cols-2 gap-[2.4rem]">
                            {currentFee?.feeBreakdown?.map((fee) => (
                                <div className="flex items-center gap-[15rem]">
                                    <Text align="left" size="1.4rem" weight="400" transform="capitalize">
                                        {fee?.description}
                                    </Text>
                                    <Text align="left" size="1.4rem" weight="400">
                                        {currencyFormatter.format(fee?.amount || 0)}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </FeeDetailsContainer>

            <CTabs>
                <CNav variant="tabs" className="">
                    <CNavItem>
                        <CNavLink>Not Paid Students</CNavLink>
                    </CNavItem>
                    {currentFee?.paymentSchedule?.toLowerCase() === 'one-off' ? (
                        ''
                    ) : (
                        <CNavItem>
                            <CNavLink>Installments</CNavLink>
                        </CNavItem>
                    )}
                    <CNavItem>
                        <CNavLink>Paid Students</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <NotPaidStudents
                            id={id}
                            setUnpaidStudents={setUnpaidStudents}
                            unpaidStudents={unpaidStudents}
                            loading={isLoading}
                            currentFee={currentFee}
                        />
                    </CTabPane>
                    {currentFee?.paymentSchedule?.toLowerCase() === 'one-off' ? null : (
                        <CTabPane>
                            <Installment
                                id={id}
                                setInstallmentStudents={setInstallmentStudents}
                                installment={installmentStudents}
                                loading={isLoading}
                                currentFee={currentFee}
                            />
                        </CTabPane>
                    )}
                    <CTabPane>
                        <PaidStudents
                            id={id}
                            setPaidStudents={setPaidStudents}
                            paidStudents={paidStudents}
                            loading={isLoading}
                            currentFee={currentFee}
                        />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </>
    );
};

export default BursaryStudentDetails;

import React, { useEffect, useState } from 'react';

import { SelectInput } from '@belrald_hq/belrald-ui';
import { Country, State } from 'country-state-city';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as PlusCircle } from '../../assets/icons/circlePlus.svg';
import { LoadingButton } from '../../components/buttons';
import { CancelButton } from '../../components/forms/sharedStyles';
import TextInput from '../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../containers/ScreenContainers';
import { updateCampus } from '../../redux/campus-management/actions';

import { CampusValidationSchema } from './add-campus';

const Container = tw.div`
bg-white
h-auto
max-w-[1000px]
px-[3.2rem]
py-[3.6rem]
rounded-[16px]
`;

const EditCampus = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.campus);
    const { id: campusId } = useParams();
    const { state } = useLocation();

    const { campus } = state || {};
    const [countryCode, setCountryCode] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (!country) return;
        const selectedCountry = countries.find((data) => data.name === country);
        setCountryCode(selectedCountry?.isoCode);
    }, [country, countries]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Campus
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Edit Campus
                </PageTitle>
            </FlexCentredRow>

            <Formik
                initialValues={{
                    campusName: campus?.campusName || '',
                    campusAddress: campus?.campusAddress || '',
                    country: campus?.country || '',
                    description: campus?.description || '',
                    state: campus?.state || '',
                    zipCode: campus?.zipCode || '',
                    campusPhoneNumber: campus?.campusPhoneNumber || '',
                    campusEmail: campus?.campusEmail || '',
                }}
                validationSchema={CampusValidationSchema}
                onSubmit={async (values, actions) => {
                    const res = await dispatch(updateCampus(values, campusId));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ handleChange, errors, setFieldValue, values }) => (
                    <Form>
                        <div className="max-w-[900px]">
                            <Container>
                                <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                    <PlusCircle fill="#6366f1" />
                                    <Text size="1.6rem" weight="600">
                                        Edit Campus
                                    </Text>
                                </FlexCentredRow>
                                <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid mt-[2.5rem]">
                                    <FlexCentredCol>
                                        <TextInput
                                            name="campusName"
                                            onChange={handleChange}
                                            label="Campus Name"
                                            type="text"
                                            placeholder="Input Campus Name"
                                            errors={errors}
                                            value={values?.campusName}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="campusAddress"
                                            onChange={handleChange}
                                            label="Campus Address"
                                            type="text"
                                            placeholder="Input Address"
                                            errors={errors}
                                            value={values?.campusAddress}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <SelectInput
                                            name="country"
                                            label="Country"
                                            objProp="name"
                                            data={[{ name: 'Nigeria' }]}
                                            placeholder="Country"
                                            onChange={(selected) => {
                                                setFieldValue('country', selected[0].name);
                                                setCountry(selected[0].name);
                                            }}
                                            error={errors.country}
                                            passedSelectedItems={values?.country}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <SelectInput
                                            name="state"
                                            label="State / Province"
                                            objProp="name"
                                            data={states?.map((item) => ({ name: item.name }))}
                                            placeholder="State / Province"
                                            onChange={(selected) => {
                                                setFieldValue('state', selected[0].name);
                                            }}
                                            error={errors.state}
                                            passedSelectedItems={values?.state}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="description"
                                            onChange={handleChange}
                                            label="Description (optional)"
                                            type="text"
                                            placeholder="Input description"
                                            errors={errors}
                                            value={values?.description}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="zipCode"
                                            onChange={handleChange}
                                            label="Zip Code (optional)"
                                            type="text"
                                            placeholder="Input Zip code"
                                            errors={errors}
                                            value={values?.zipCode}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="campusEmail"
                                            onChange={handleChange}
                                            label="Campus Email (optional)"
                                            type="text"
                                            placeholder="Input Campus Email"
                                            errors={errors}
                                            value={values?.campusEmail}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="campusPhoneNumber"
                                            onChange={handleChange}
                                            label="Campus Phone Number (Optional)"
                                            type="text"
                                            placeholder="Input Phone Number"
                                            errors={errors}
                                            value={values?.campusPhoneNumber}
                                        />
                                    </FlexCentredCol>
                                </div>
                            </Container>
                            <div className="flex items-center gap-[1rem] my-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton isLoading={isLoading} bgColor="#6366F1" color="#fff" type="submit">
                                    Edit Campus
                                </LoadingButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {/* </Container> */}
        </>
    );
};

export default EditCampus;
